import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IAuditSearch } from '@depot/@data';
import { IAuditReport, IAuditReportAggregate, IAuditRow, IPagedResults } from '@depot/custom';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class AuditRepositoryService {

  constructor(private httpClient: HttpClient) { }

  public searchAudit(filters: IAuditSearch) {
    const url = environment.get_endpoint(`audit`);

    return this.httpClient.get<IPagedResults<IAuditReportAggregate>>(url, { params: <any>filters });

  }

  public getAuditData(id: number) {
    const url = environment.get_endpoint(`audit/${id}`);

    return this.httpClient.get<IAuditReport>(url);
  }

  public saveAudit(id: number, saveData: Partial<IAuditReport>) {
    const url = environment.get_endpoint(`audit/${id}`);

    return this.httpClient.put<IAuditReport>(url, saveData);
  }

  public saveAuditRows(id: number, saveData: IAuditRow[]) {
    const url = environment.get_endpoint(`audit/${id}`);

    return this.httpClient.patch<IAuditRow[]>(url, saveData);
  }

  public deleteAuditRow(id: number) {
    const url = environment.get_endpoint(`audit/${id}`);

    return this.httpClient.delete(url);
  }


  public exportAuditData(id: number) {
    const url = environment.get_endpoint(`audit/export/${id}`);

    return this.httpClient.get(url, { responseType: 'text' });
  }


}
