import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IEmailModel, IO365MailModel } from '@depot/custom';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class O365RepositoryService {

  constructor(private httpClient: HttpClient) { }

  // public searchOrders(filters: IOrderSearch) {
  //   const url = environment.get_endpoint('order');

  //   return this.httpClient.get<IPagedResults<IOrder>>(url, { params: <any>filters });
  // }


  // public getOrderById(id: number) {
  //   const url = environment.get_endpoint(`order/${id}`);

  //   return this.httpClient.get<IOrder>(url);

  // }

  // public getOrderByPart(line: string, number: string) {
  //   const url = environment.get_endpoint(`order/${line}/${number}`);

  //   return this.httpClient.get<IOrder[]>(url);

  // }

  // public getPreview(id: string) {
  //   const url = environment.get_endpoint(`O365/GetPreview?messageId=` + id);

  //   return this.httpClient.get<any>(url);

  // }

  public getSavedByMessageId(id: string) {
    const url = environment.get_endpoint(`O365/GetSavedByMessageId/${id}`);

    return this.httpClient.get<IO365MailModel>(url);

  }

  public getSavedByConversationId(id: string) {
    const url = environment.get_endpoint(`O365/GetSavedByConversationId/${id}`);

    return this.httpClient.get<IO365MailModel[]>(url);

  }

  public mergeEmailTemplate(emailLog: IEmailModel) {
    const url = environment.get_endpoint(`O365/MergeEmailTemplate`);

    return this.httpClient.post<{ body: string }>(url, emailLog);

  }

  public sendMail(value: IEmailModel) {
    const url = environment.get_endpoint(`O365/SendMail`);

    return this.httpClient.post<IO365MailModel>(url, value);

  }

  public replyAll(value: IEmailModel) {
    const url = environment.get_endpoint(`O365/ReplyAll`);

    return this.httpClient.post<IO365MailModel>(url, value);

  }

  public markAsRead(id: string) {
    const url = environment.get_endpoint(`O365/MarkAsRead/${id}`);
    const value = { id: id };
    return this.httpClient.get<IO365MailModel>(url);

  }

}
