import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'defaultIfSet',
  pure: true,
})
export class DefaultIfSetPipe implements PipeTransform {

  transform(value: any, secondaryProperty: any, textToShow: string, args?: any): any {
    // if the isn't set but a secondary property is show some default text
    if (!value && secondaryProperty) {
      return textToShow;
    }
    return value;
  }

}
