import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'depot-camera-dialog',
  template: `
  <div mat-dialog-content>

    <depot-camera #depotCamera
                  (capture)="dialogRef.close($event)"></depot-camera>
  </div>
  <div mat-dialog-actions align="end">

    <button mat-raised-button
            (click)="dialogRef.close(null)">Cancel</button>
    <button mat-raised-button
            color="primary"
            (click)="depotCamera?.onCapture()">Capture Image</button>

  </div>
  `
})
export class CameraDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<CameraDialogComponent>,
  ) {

  }


}
