import { HttpStatusCode } from '@angular/common/http';

import { DepotContextService, DepotTables, WindowWrapper } from '@depot/@common';
import { environment } from '@env';

import { subHours } from 'date-fns';
import { mapStackTrace } from 'sourcemapped-stacktrace';

export enum LogLevels {
  debug = 0,
  info = 1,
  warn = 2,
  error = 3,
  fatal = 4,
  none = 99,
}

// https://v8.dev/docs/stack-trace-api
export class ClientLogging {
  private settingsDefault: ILogSettings = {
    application: 'Client App',
    debug: true,
    endPoint: '/api/logger',
    filter: (msg) => true,
  };
  private errorsDates = [];

  constructor(
    private settings: ILogSettings,
    private window: WindowWrapper,
    private depotContext: DepotContextService,
  ) {
    this.settings = Object.assign(this.settingsDefault, this.settings);

    this.window.addEventListener('error', (errorEvent: ErrorEvent) => {
      const error = errorEvent?.error;
      this.log(LogLevels.error, error?.message || 'Unspecified Error', error, null);
      return true;
    });
    // const onError = this.window.onerror;
    // this.window.onerror = (msg, file, line, col, error: Error) => {
    //   this.log("error", msg, error);
    //   if (onError) {
    //     onError(msg, file, line, col, error);
    //   }
    // };
  }

  public debug(log: string, error: Error | Object | null = new Error(), extra: Object | null = null) {
    this.log(LogLevels.debug, log, error, extra);
  }

  public info(log: string, error: Error | Object | null = new Error(), extra: Object | null = null) {
    this.log(LogLevels.info, log, error, extra);
  }

  public warn(log: string, error: Error | Object | null = new Error(), extra: Object | null = null) {
    this.log(LogLevels.warn, log, error, extra);
  }

  public error(log: string, error: Error | Object | null = new Error(), extra: Object | null = null) {
    this.log(LogLevels.error, log, error, extra);
  }

  private log(logType: LogLevels, log: string, error: Error | Object | null, extra: Object | null) {
    try {
      this.errorsDates.push(new Date());
      this.errorsDates = this.errorsDates.filter(date => subHours(new Date(), 12) > date);
      if (this.errorsDates.length >= 500) {
        return;
      } else if (this.error.length === 499) {
        log = 'To many errors detected. No more errors will be sent to the server\n\n' + log;
        logType = LogLevels.fatal;
      }
    } catch (e) { console.log('test', e); }

    this.getPayload(logType, log, <Error>error, extra)
      .then(payload => {
        if (!this.settings.filter(payload)) {
          return;
        }
        const xhr = new XMLHttpRequest();
        xhr.open('POST', this.settings.endPoint, true);
        xhr.setRequestHeader('Content-type', 'application/json');

        xhr.onload = e => {
          if (xhr.readyState === 4 && xhr.status === HttpStatusCode.Ok) {
            this.callback(LogLevels.debug, xhr.statusText);
          }
        };

        xhr.onerror = e => {
          this.callback(LogLevels.error, xhr.statusText);
        };
        xhr.send(JSON.stringify(payload));
        this.callback(logType, log, <Error>error);
      })
      .catch(err => {
        this.callback(LogLevels.error, err);
      });
  }

  private getErrorName(error: any) {
    if (error && error.name) {
      return error.name;
    } else if (error && error.error) {
      return this.getErrorName(error.error);
    }

    return null;
  }

  private callback(status: LogLevels, response, error: Error = null, extra: unknown = null) {
    if (this.settings.debug !== true) {
      return;
    }
    let message = '';
    let style = '';
    if (typeof response === 'string') {
      message = response;
    } else if (response instanceof Error) {
      message = response.toString();
    } else {
      message = JSON.stringify(response);
    }

    if (status === LogLevels.error) {
      style = 'background: #ffa8a8; color: #000000;';

    } else if (status === LogLevels.warn) {
      style = 'background: #ded052; color: #000000;';

    } else if (status === LogLevels.debug) {
      style = 'background: #e1e6f5; color: #000000;';

    } else if (status === LogLevels.info) {
      style = 'background: #7796ed; color: #000000;';

    } else {
      style = 'background: #6ce66e; color: #000000;';

    }

    console.log(`%c ${(LogLevels[status] || '').toUpperCase()} ` + message, style, error ?? '', extra ?? '');

  }
  private async getPayload(
    logType: LogLevels,
    message: string,
    error: Error,
    extra: any | null
  ): Promise<Message> {
    let stackFrame: string[] = [];
    let stack = '';
    try {
      if (!error) {
        error = new Error();
      }
      //  stackFrame = await fromError(error);
      // stack = (stackFrame ?? []).map(sf => sf.toString()).join('\n');
      stack = error.stack;
      const t = mapStackTrace(stack, (d) => {
        stackFrame = d;
      }, { sync: true, cacheGlobally: true });

    } catch (err) {
      stack = err.message;
    }

    const now = new Date();
    const payload: Message = {
      application: this.settings.application,
      message: message,
      level: <any>LogLevels[logType].toString().toUpperCase(),
      source: error.name, // stackFrame.length > 0 ? stackFrame[0].fileName : null,
      url: document.location.pathname ?? '/',
      exception: error.stack && error.message ? error.toString() : '',
      stackTrace: stackFrame.length === 0 ? stack : stackFrame.join('\n'), // stack,
      errorType: this.getErrorName(error) ?? '',
      clientTime: now.toString(),
      clientUser: this.window.user,
      version: environment?.version ?? '0',
      isClient: true,
      loadDate: this.window.startTime.toString(),
      loadTime: -1,
      logTime: -1,
      indexDbQuota: -1,
      indexDbUsage: -1,
      indexDb: [],
      localStorage: [],
      queryString: [],
      clientData: [],
      serverVariables: [],
      cookies: [],
      sessionStorage: []
    };

    if (this.window.loadTime && this.window.startTime) {
      payload.loadTime =
        (this.window.loadTime.getTime() - this.window.startTime.getTime()) /
        1000;
    }

    if (this.window.startTime) {
      payload.logTime =
        (now.getTime() - this.window.startTime.getTime()) / 1000;
    }

    if (extra) {
      payload.extra = this.mapExtraData(extra);

      // for (const key in extra) {
      //   if (extra.hasOwnProperty(key)) {
      //     payload.extra.push({
      //       key: key,
      //       value: extra[key]
      //     });
      //   }
      // }
    }

    this.addClientData(payload);

    this.addServerVariables(payload);
    try {

      const db: { id: string; userName: string; data: any }[] = await this.depotContext.getAllAsync(DepotTables.settings);
      for (const key of db) {
        payload.indexDb.push({
          key: `${key.id}:${key.userName}`,
          value: JSON.stringify(key.data)
        });
        // payload.indexDb.push(...this.mapExtraData(key.data, `${key.id}:${key.userName}`));
      }
    } catch (err) {

    }
    try {
      const quota = await this.depotContext.getUsageAsync();
      payload.indexDbQuota = quota.quota;
      payload.indexDbUsage = quota.usage;
    } catch (err) {

    }
    for (const key in localStorage) {
      if (localStorage.hasOwnProperty(key)) {
        payload.localStorage.push({
          key: key,
          value: localStorage[key]
        });
      }
    }

    for (const key in sessionStorage) {
      if (sessionStorage.hasOwnProperty(key)) {
        payload.sessionStorage.push({
          key: key,
          value: sessionStorage[key]
        });
      }
    }

    // const cookies = document.cookie.split(';');
    // for (const key in cookies) {
    //   if (document.cookie.hasOwnProperty(key)) {
    //     const values = cookies[key].split('=');
    //     payload.cookies.push({
    //       key: values[0],
    //       value: values[1]
    //     });
    //   }
    // }

    const queryString = new URLSearchParams(this.window.location.search);
    for (const key in (<any>queryString).entries()) {
      if (queryString.hasOwnProperty(key)) {
        payload.cookies.push({
          key: key[0],
          value: key[1]
        });
      }
    }

    // cleanup extra properties that weren't set
    for (const key of Object.keys(payload)) {
      const item = payload[key];
      if (Array.isArray(item) && item.length === 0) {
        delete payload[key];
      }
    }

    if (!payload.source || payload.source.length === 0) {
      delete payload.source;
    }

    return payload;
  }

  private mapExtraData(extra: object, parentKey = '', returnObject = []) {
    if (typeof extra === 'string' || typeof extra === 'number' || typeof extra === 'boolean') {
      returnObject.push({
        key: parentKey,
        value: extra
      });
    } else {
      for (const key in extra) {
        if (extra.hasOwnProperty(key)) {
          if (typeof extra[key] === 'object') {
            this.mapExtraData(extra[key], parentKey + key + '.', returnObject);
          } else if (typeof extra[key] !== 'function') {
            returnObject.push({
              key: parentKey + key,
              value: extra[key]
            });
          }
        }
      }
    }
    return returnObject;
  }

  private addClientData(payload: Message) {
    if (navigator.language) {
      payload.clientData.push({
        key: 'User-Language',
        value: navigator.language
      });
    }

    if (
      this.window.innerWidth ||
      document.documentElement.clientWidth ||
      document.getElementsByTagName('body')[0].clientWidth
    ) {
      payload.clientData.push({
        key: 'Browser-Width',
        value:
          this.window.innerWidth ||
          document.documentElement.clientWidth ||
          document.getElementsByTagName('body')[0].clientWidth
      });
    }
    if (
      this.window.innerHeight ||
      document.documentElement.clientHeight ||
      document.getElementsByTagName('body')[0].clientHeight
    ) {
      payload.clientData.push({
        key: 'Browser-Height',
        value:
          this.window.innerHeight ||
          document.documentElement.clientHeight ||
          document.getElementsByTagName('body')[0].clientHeight
      });
    }
    if ((screen.orientation || { type: undefined }).type !== undefined) {
      payload.clientData.push({
        key: 'Screen-Orientation',
        value: screen.orientation.type.split('-')[0]
      });
    }
    if (screen.width) {
      payload.clientData.push({
        key: 'Screen-Width',
        value: screen.width
      });
    }
    if (screen.height) {
      payload.clientData.push({
        key: 'Screen-Height',
        value: screen.height
      });
    }
    if (screen.colorDepth) {
      payload.clientData.push({
        key: 'Color-Depth',
        value: screen.colorDepth
      });
    }
  }

  private addServerVariables(payload: Message) {
    // if (navigator.userAgent) {
    //   payload.serverVariables.push({
    //     key: 'User-Agent',
    //     value: navigator.userAgent
    //   });
    // }
    // if (document.referrer) {
    //   payload.serverVariables.push({
    //     key: 'Referer',
    //     value: document.referrer
    //   });
    // }
    // if (document.location.protocol === 'https:') {
    //   payload.serverVariables.push({
    //     key: 'HTTPS',
    //     value: 'on'
    //   });
    // }
    // if (document.location.hostname) {
    //   payload.serverVariables.push({
    //     key: 'Host',
    //     value: document.location.hostname
    //   });
    // }
  }

}


interface Item {
  key: string;
  value: string | number;
}
interface Message {
  // [key: keyof Message]: string | LogLevels | Item[] | number | boolean;
  /**
   * Used to identify which application logged this message.
   * You can use this if you have multiple applications and services logging to the same log.
   */
  application: string;
  /**
   * A longer description of the message. For errors this could be a stacktrace, but it's really up to you what to log in there.
   */
  stackTrace: string;
  /**
   * The hostname of the server logging the message.
   */
  // hostname: string;
  /**
   * The textual title or headline of the message to log.
   */
  message: string;
  /**
   * The source of the code logging the message. This could be the assembly name.
   */
  source: string;
  /**
   * If the message logged relates to a HTTP status code, you can put the code in this property.
   * This would probably only be relevant for errors, but could be used for logging successful status codes as well.
   */
  // statusCode: number;
  /**
   * The date and time in UTC of the message. If you don't provide us with a value in dateTime,
   * we will set the current date and time in UTC.
   */
  clientTime: string;
  /**
   * The type of message. If logging an error, the type of the exception would go into type but you can put anything in there,
   * that makes sense for your domain.
   */
  errorType: string;
  /**
   * An identification of the user triggering this message. You can put the users email address or your user key into this property.
   */
  clientUser: string;
  /**
   * An enum value representing the severity of this message. The following values are allowed: Verbose, Debug, Information,
   * Warning, Error, Fatal
   */
  level: LogLevels;
  /**
   * If message relates to a HTTP request, you may send the URL of that request. If you don't provide us with an URL,
   * we will try to find a key named URL in serverVariables.
   */
  url: string;
  exception: string;
  /**
   * If message relates to a HTTP request, you may send the HTTP method of that request. If you don't provide us with a method,
   * we will try to find a key named REQUEST_METHOD in serverVariables.
   */
  // method: string;
  /**
   * Versions can be used to distinguish messages from different versions of your software. The value of version can be a
   * SemVer compliant string or any other syntax that you are using as your version numbering scheme.
   */
  version: string;
  /**
   * A key/value pair of cookies. This property only makes sense for logging messages related to web requests.
   */
  cookies: Array<Item>;
  /**
   * A key/value pair of form fields and their values. This property makes sense if logging message related to
   * users inputting data in a form.
   */
  // form: Array<Item>;
  /**
   * A key/value pair of query string parameters. This property makes sense if logging message related to a HTTP request.
   */
  queryString: Array<Item>;
  /**
   * A key/value pair of server values. Server variables are typically related to handling requests in a webserver
   * but could be used for other types of information as well.
   */
  serverVariables: Array<Item>;
  /**
   * A key/value pair of user-defined fields and their values. When logging an exception, the Data dictionary of
   * the exception is copied to this property. You can add additional key/value pairs, by modifying the Data dictionary
   * on the exception or by supplying additional key/values to this API.
   */
  clientData: Array<Item>;
  sessionStorage?: Array<Item>;
  extra?: Array<Item>;
  localStorage: Array<Item>;
  indexDb: Array<Item>;
  indexDbQuota: number;
  indexDbUsage: number;
  loadDate: string;
  loadTime: number;
  logTime: number;
  isClient: boolean;
}

interface ILogSettings {
  endPoint: string;
  application: string;
  debug: boolean;
  filter: (message: Message) => boolean;
}
