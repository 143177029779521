import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IPutAwaySearch } from '@depot/@data';
import {
  IPagedResults,
  IPutAway,
  IPutawayAggregate,
  IPutAwayReport,
  IScrapReport,
  IScrapReportRow,
} from '@depot/custom';
import { environment } from '@env';

import { compare } from 'fast-json-patch';

@Injectable({
  providedIn: 'root'
})
export class PutawayRepositoryService {

  constructor(private httpClient: HttpClient) { }

  public searchPutAway(filters: IPutAwaySearch) {
    const url = environment.get_endpoint(`putaway`);

    return this.httpClient.get<IPagedResults<IPutawayAggregate>>(url, { params: <any>filters });

  }

  public getPutAwayData(id: number) {
    const url = environment.get_endpoint(`putaway/${id}`);

    return this.httpClient.get<IPutAwayReport>(url);
  }

  public getByScrapReportId(scrapReportId: number) {
    const url = environment.get_endpoint(`putaway/GetByScrapReportId/${scrapReportId}`);

    return this.httpClient.get<IPutAwayReport[]>(url);
  }

  public savePutaway(id: number, saveData: IPutAwayReport) {
    const url = environment.get_endpoint(`putaway/${id}`);

    return this.httpClient.put<IPutAwayReport>(url, saveData);
  }

  public savePutawayPartial(id: number, putawayReport: IPutAwayReport, originalPutawayReport: IPutAwayReport) {
    const url = environment.get_endpoint(`putaway/${id}`);

    const patch = compare(originalPutawayReport, putawayReport);
    if (patch.every(x => x.path.toLowerCase().includes('rowversion'))) {
      patch.splice(0);
    }

    return this.httpClient.patch<IPutAwayReport>(url, patch);
  }

  public deletePutAway(id: number) {
    const url = environment.get_endpoint(`putaway/${id}`);

    return this.httpClient.delete(url);
  }

  public exportPutAwayData(putawayReportId: number) {
    const url = environment.get_endpoint(`putaway/export/${putawayReportId}`);

    return this.httpClient.get(url, { responseType: 'text' });
  }



}
