export class StackFormat {

  public static netStack(stacktrace: string) {

    const settings = {
      frame: 'st-frame',
      type: 'st-type',
      method: 'st-method',
      paramsList: 'st-frame-params',
      paramType: 'st-param-type',
      paramName: 'st-param-name',
      file: 'st-file',
      line: 'st-line'
    };
    const lines = stacktrace.split('\n');
    let clone = '';
    for (let i = 0, j = lines.length; i < j; ++i) {
      let li = lines[i];
      const hli = new RegExp('\\bat .*\\)');
      if (hli.test(lines[i])) {
        const regFrame = new RegExp('\\bat .*\\)');
        let partsFrame = String(regFrame.exec(lines[i]));
        partsFrame = partsFrame.replace('at ', '');
        const regParamList = new RegExp('\\(.*\\)'),
          partsParamList = String(regParamList.exec(lines[i]));
        const partsParams = partsParamList.replace('(', '').replace(')', ''),
          arrParams = partsParams.split(', ');
        let stringParam = '';
        for (let x = 0, y = arrParams.length; x < y; ++x) {
          let theParam = '';
          const param = arrParams[x].split(' '),
            paramType = param[0],
            paramName = param[1];
          if (param[0] !== 'null' && param[0] !== '') {
            theParam = '<span class="' + settings.paramType + '">' +
              paramType + '</span>' + ' ' + '<span class="' + settings.paramName + '">' + paramName + '</span>';
            stringParam += String(theParam) + ', ';
          }
        }
        stringParam = stringParam.replace(/,\s*$/, '');
        stringParam = '<span class="' + settings.paramsList + '">' + '(' + stringParam + ')' + '</span>';
        const partsTypeMethod = partsFrame.replace(partsParamList, ''),
          arrTypeMethod = partsTypeMethod.split('.'),
          method = arrTypeMethod.pop(),
          type = partsTypeMethod.replace('.' + method, ''),
          stringTypeMethod = '<span class="' + settings.type + '">' +
            type + '</span>.' + '<span class="' + settings.method + '">' + method + '</span>';
        const newPartsFrame = partsFrame.replace(partsParamList, stringParam).replace(partsTypeMethod, stringTypeMethod);
        const regLine = new RegExp('\\b:line.*');
        let partsLine = String(regLine.exec(lines[i]));
        partsLine = partsLine.replace(':', '');
        const regFile = new RegExp('\\bin.*$');
        let partsFile = String(regFile.exec(lines[i]));
        partsFile = partsFile.replace('in ', '').replace(':' + partsLine, '');
        li = li.replace(partsFrame, '<span class="' + settings.frame + '">' + newPartsFrame + '</span>')
          .replace(partsFile, '<span class="' + settings.file + '">' + partsFile + '</span>')
          .replace(partsLine, '<span class="' + settings.line + '">' + partsLine + '</span>');
        if (lines.length - 1 === i) {
          clone += li;
        } else {
          clone += li + '\n';
        }
      } else {
        if ((lines[i].trim()).length) {
          li = lines[i];
          if (lines.length - 1 === i) {
            clone += li;
          } else {
            clone += li + '\n';
          }
        }
      }
    }
    return clone;
  }

  public static jsStack(stacktrace: string) {

    const settings = {

      // Default values for classes
      method: 'st-methodName',
      file: 'st-fileName',
      line: 'st-lineNumber',
      column: 'st-column'

    };

    const UNKNOWN_FUNCTION = '<unknown>';
    const chrome = /^\s*at (?:(?:(?:Anonymous function)?|((?:\[object object\])?\S+(?: \[as \S+\])?)) )?\(?((?:file|http|https):.*?):(\d+)(?::(\d+))?\)?\s*$/i,
      gecko = /^(?:\s*([^@]*)(?:\((.*?)\))?@)?(\S.*?):(\d+)(?::(\d+))?\s*$/i,
      node = /^\s*at (?:((?:\[object object\])?\S+(?: \[as \S+\])?) )?\(?(.*?):(\d+)(?::(\d+))?\)?\s*$/i,
      other = /^\s*at (?:(?:(?:Anonymous function)?|((?:\[object object\])?\S+(?: \[as \S+\])?)) )\(?((?:).*?)?\)?\s*$/i;

    const lines = stacktrace.split('\n');
    let stack = '',
      parts,
      element;

    function template_line(line, e) {
      line = line.replace(e.file, '<span class="' + settings.file + '">' + e.file + '</span>')
        .replace(e.methodName + ' (', '<span class="' + settings.method + '">' + e.methodName + '</span> (')
        .replace(':' + e.lineNumber + ':' + e.column, ':<span class="' + settings.line + '">' +
          e.lineNumber + '</span>:<span class="' + settings.column + '">' + e.column + '</span>');

      return line;
    }

    function escapeHtml(unsafe) {
      return unsafe
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#039;');
    }

    for (let i = 0, j = lines.length; i < j; ++i) {
      let line = '';

      if ((parts = gecko.exec(lines[i]))) {
        element = {
          'file': parts[3],
          'methodName': parts[1] || UNKNOWN_FUNCTION,
          'lineNumber': +parts[4],
          'column': parts[5] ? +parts[5] : null
        };
        line = template_line(lines[i], element);
      } else if ((parts = chrome.exec(lines[i]))) {
        element = {
          'file': parts[2],
          'methodName': parts[1] || UNKNOWN_FUNCTION,
          'lineNumber': +parts[3],
          'column': parts[4] ? +parts[4] : null
        };
        line = template_line(lines[i], element);
      } else if ((parts = node.exec(lines[i]))) {
        element = {
          'file': parts[2],
          'methodName': parts[1] || UNKNOWN_FUNCTION,
          'lineNumber': +parts[3],
          'column': parts[4] ? +parts[4] : null
        };
        line = template_line(lines[i], element);
      } else if ((parts = other.exec(lines[i]))) {
        element = {
          'file': parts[2],
          'methodName': parts[1] || UNKNOWN_FUNCTION
        };
        line = template_line(lines[i], element);
      } else {
        line = lines[i];
      }

      if (lines.length - 1 === i) {
        stack += line;
      } else {
        stack += line + '\n';
      }
    }

    return stack;
  }
}
