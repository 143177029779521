import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { HelperService, ImageService, SettingsService } from '@depot/@common';

import { BehaviorSubject, filter, fromEvent } from 'rxjs';

@Component({
  selector: 'depot-camera',
  template: `
  <div style="display: flex; flex-flow: column; flex-grow: inherit;">
    <mat-form-field *ngIf="(cameras | async)?.length > 1">
      <mat-label>Selected Camera</mat-label>
      <mat-select [value]="selectedCamera | async"
                  (selectionChange)="selectedCamera.next($event.value)">
        <mat-option *ngFor="let camera of (cameras | async); let i = index"
                    [value]="camera?.deviceId">
          {{camera.label || 'Camera ' + (i + 1)}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <video #cameraSrc
           class="dp-sticky-item"
           (dblclick)="onCapture()"
           autoplay>
    </video>
  </div>
  `,
  styles: [`
  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CameraComponent implements AfterViewInit, OnDestroy {

  @Output() public capture = new EventEmitter<string>();

  @ViewChild('cameraSrc') video: ElementRef<HTMLVideoElement>;
  public cameras = new BehaviorSubject<MediaDeviceInfo[]>([]);
  public selectedCamera = new BehaviorSubject<string>(null);

  constructor(
    private imageSvc: ImageService,
    private helper: HelperService,
    private settingService: SettingsService,
    private renderer: Renderer2,
    private destroyRef: DestroyRef,

  ) {
  }

  ngAfterViewInit() {
    this.selectedCamera
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(async camId => {
        if (!camId || !this.video) {
          return;
        }
        await this.settingService.selectedCamera(camId);
        // this.imageSvc.SelectedCamera = camId;
        try {
          const imgStream = await this.imageSvc.getStream(camId);
          this.renderer.setProperty(this.video.nativeElement, 'srcObject', imgStream);

          // this.video.nativeElement.srcObject = await this.imageSvc.getStream(camId);
          // const track = this.video.nativeElement.srcObject.getVideoTracks()[0];
          // const capabilities = track.getCapabilities();
          // this.helper.logger.debug('capabilities', new Error(JSON.stringify(capabilities)));
        } catch (err) {
          // this.selectedCamera.next(null);
          this.helper.showMessage(err.message);
          throw err;
        }
      });

    fromEvent(document, 'keydown')
      .pipe(
        filter((x: KeyboardEvent) => x.key === 'F10'),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(x => {
        x.preventDefault();
        this.onCapture();
      });

    Promise.all([
      this.imageSvc.getCamerasAsync(),
      this.settingService.selectedCamera()
    ]).then(response => {
      const cameras = response[0];
      const savedCamera = response[1];
      this.cameras.next(cameras);
      if (this.cameras.getValue().some(cams => cams?.deviceId === savedCamera)) {
        this.selectedCamera.next(savedCamera);
      } else if (this.cameras.getValue().length > 0) {
        this.selectedCamera.next(this.cameras.getValue()[0].deviceId);
      } else {

      }
    }).catch(err => this.helper.showMessage(err.message));


  }

  ngOnDestroy() {
    if (this.video?.nativeElement.srcObject) {
      (this.video.nativeElement.srcObject as MediaStream).getVideoTracks().forEach(track => track.stop());
    }

  }

  public async onCapture() {
    // const image = await this.imageSvc.videoToBlobAsync(this.video.nativeElement);
    const image = this.imageSvc.videoToUrl(this.video.nativeElement);
    this.capture.emit(image);


  }

}

