import { HttpClient, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { HelperService, LogLevels, NeverCancelHeader, RoleGuard, WindowWrapper } from '@depot/@common';
import { IWebUser } from '@depot/custom';
import { environment } from '@env';

import { BehaviorSubject, catchError, map, Observable, of, ReplaySubject, tap, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  /**
 * Can be undefined, null, or an IWebUser;
 * Undefined means nothing we are waiting to hear from the API
 * Null means they are not authenticated
 */
  private userSource = new BehaviorSubject<IWebUser>(undefined);
  public user$ = this.userSource.asObservable();

  /**
   * Can be undefined, null, or an IWebUser;
   * Undefined means nothing we are waiting to hear from the API
   * Null means they are not authenticated
   */
  public get user() {
    return this.userSource.value;
  }

  public set user(value: IWebUser | null) {
    this.userSource.next(value);
  }
  public isInRole$: { [key: string]: ReplaySubject<boolean> };

  constructor(
    private httpClient: HttpClient,
    private window: WindowWrapper,
    private helper: HelperService,
  ) {
    this.isInRole$ = {

      [RoleGuard.Admin]: new ReplaySubject<boolean>(),
      [RoleGuard.UserAdmin]: new ReplaySubject<boolean>(),
      [RoleGuard.AuditAdmin]: new ReplaySubject<boolean>(),
      [RoleGuard.SiteAdmin]: new ReplaySubject<boolean>(),
      [RoleGuard.PickupAdmin]: new ReplaySubject<boolean>(),
      [RoleGuard.IncomingAdmin]: new ReplaySubject<boolean>(),
      [RoleGuard.WeightTicketAdmin]: new ReplaySubject<boolean>(),
      [RoleGuard.ScrapAdmin]: new ReplaySubject<boolean>(),
      [RoleGuard.PriceBookEditAdmin]: new ReplaySubject<boolean>(),
      [RoleGuard.OrderManager]: new ReplaySubject<boolean>(),
      [RoleGuard.PickupSuperAdmin]: new ReplaySubject<boolean>(),

    };
    this.user$.subscribe(x => {
      for (const key of Object.keys(this.isInRole$)) {
        this.isInRole$[key].next(this.isInRole(key));
      }
    });


  }


  public isInRole(role: string | string[]): boolean {
    if (!this.user) {
      return false;
    }
    if (!role || role.length === 0) {
      return true;
    }
    if (Array.isArray(role)) {
      return role.some(eachRole => this.isInRole(eachRole));
    }
    return this.user.roleList.some(
      userRole => userRole.toLocaleLowerCase() === role.toLowerCase()
    );
  }

  public login(userName: string, password: string
  ): Observable<IWebUser> {
    const url = environment.get_endpoint('auth/authenticate');
    return this.httpClient
      .post<IWebUser>(url, {
        UserName: userName,
        Password: password
      })
      .pipe(
        tap(x => {
          if (x) {
            this.helper.logger.debug('Logging user in-' + x.userName);
          } else {
            this.helper.logger.debug('Invalid log attempt-' + userName);
          }
        }),
        map(response => {
          if (response) {
            this.user = response;
            this.window.user = this.user.userName;
            this.window.logLevel = LogLevels[this.user.logLevel];
          }

          return response;
        },

        ));
  }

  public getUserData(): Observable<IWebUser> {
    const url = environment.get_endpoint('auth/userData');
    return this.httpClient.get<IWebUser>(url, { headers: NeverCancelHeader })
      .pipe(
        tap(x => {
          if (x) {
            this.helper.logger.debug('Getting user changing-' + x.userName);
          }
        }),
        catchError((err: HttpErrorResponse) => {
          if (err.status === HttpStatusCode.Unauthorized) {
            return of(null);
          }
          return throwError(() => new Error('Could not retrieve user data.'));
        }),
        map(response => {
          this.user = response;
          if (this.user) {
            this.window.user = this.user.userName;
            this.window.logLevel = LogLevels[this.user.logLevel];
          }
          return response;
        }),

      );
  }

  public logout(): Observable<string> {
    const url = environment.get_endpoint('auth/SignOut');
    return this.httpClient
      .get<{ redirectUrl: string }>(url, { headers: NeverCancelHeader })
      .pipe(
        map(resp => {
          this.user = null;
          return '';
        })
      );
  }

  public sendResetPasswordEmail(email: string): Observable<string> {
    const url = environment.get_endpoint('auth/SendResetPasswordEmail');
    return this.httpClient
      .post(url, { email })
      .pipe(
        map(resp => '')
      );
  }

  public resetPassword(model: { token: string; id: string; password: string }): Observable<string> {
    const url = environment.get_endpoint('auth/resetPassword');
    return this.httpClient
      .post(url, model)
      .pipe(
        map(resp => '')
      );
  }


}
