import { Pipe, PipeTransform } from '@angular/core';

import { unique } from 'underscore';

@Pipe({
  name: 'join',
  pure: true
})
export class JoinPipe implements PipeTransform {

  transform(items: any[], attr: string, separationChar = ', ', distinct = true, removeNull = true): string {
    items = items ?? [];
    if (attr) {
      items = items.map(x => x[attr]);
    }
    if (distinct === true) {
      items = unique(items);
    }
    if (removeNull === true) {
      items = items.filter(x => x);
    }
    return items.join(separationChar);
  }

}
