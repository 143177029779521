import { convertNestedToDate } from '@depot/@common';

import { BehaviorSubject } from 'rxjs';

import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

export class SignalRBase<HubType extends HubConnection = HubConnection> {

  public connected$ = new BehaviorSubject(false);
  public hubConnection: HubConnection;
  constructor(url: string) {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(url)
      .configureLogging(LogLevel.Warning)
      .withAutomaticReconnect({
        // try and reconnect every 10 seconds for 40 attempts
        nextRetryDelayInMilliseconds: retryContext => retryContext.previousRetryCount <= 40 ? 10 * 1000 : null
      })
      .build();

    // Attempt to convert the date values for all incoming messages
    (<any>this.hubConnection)._origianlOn = this.hubConnection.on;
    this.hubConnection.on = (methodName, newMethod) => {
      (<any>this.hubConnection)._origianlOn(methodName, args => {
        convertNestedToDate(args);
        newMethod(args);
      });
    };

    this.hubConnection.onclose((error) => {
      this.connected$.next(false);
    });

    this.hubConnection.onreconnecting((error) => {
      this.connected$.next(false);
    });

    this.hubConnection.onreconnected((connectionId) => {
      this.connected$.next(true);
    });

  }
}
