import {
  Component,
  ComponentFactoryResolver,
  Inject,
  Injector,
  OnInit,
  Type
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'depot-message-dialog',
  template: `
  <h1 *ngIf="title"
      mat-dialog-title>{{title}}</h1>
  <div mat-dialog-content
       [innerHTML]="message">

  </div>
  <div mat-dialog-actions
    align="center">
    <button mat-raised-button
            color="primary"
            (click)="dialogRef.close()">Ok</button>
  </div>
  `,
})
export class MessageDialogComponent implements OnInit {
  public title: SafeHtml = '';
  public message: SafeHtml = '';
  public component: Type<any>;

  constructor(
    private injector: Injector,
    private sanitizer: DomSanitizer,
    private componentFactoryResolver: ComponentFactoryResolver,
    public dialogRef: MatDialogRef<MessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      title?: string;
      message?: string;
      component?: Type<any>;
    }) {

    data = data || {};
    this.title = data.title ? this.sanitizer.bypassSecurityTrustHtml(data.title) : '';
    this.message = this.sanitizer.bypassSecurityTrustHtml(data.message || '');
    this.component = data.component;
  }
  ngOnInit() {
    if (this.component) {
      // this.message = await this.compiler.compileModuleAndAllComponentsAsync(this.component);
      const factory = this.componentFactoryResolver.resolveComponentFactory(this.component);
      const element = factory.create(this.injector).location.nativeElement;
      this.message = this.sanitizer.bypassSecurityTrustHtml(element.innerHTML);
    }

  }
}
