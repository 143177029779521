import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, ValidationErrors } from '@angular/forms';

import { Subject, Subscription } from 'rxjs';

import { format } from 'date-fns';

@Component({
  selector: 'mat-error[depot-form-error]',
  template: `
    <span *ngFor="let error of (errors$ | async) | keyvalue; let i=index">
      <ng-container *ngIf="i === 0 || showAll === true">
        {{mapError(error.key, error.value)}}<br />
      </ng-container>
    </span>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormErrorComponent implements OnInit, OnDestroy {

  @Input('depot-form-error') public formError: UntypedFormGroup;
  @Input() public errorControlName: string;
  @Input() public errors: ValidationErrors;
  @Input() public showAll = false;
  private sub: Subscription;
  public errors$ = new Subject<ValidationErrors>();
  constructor() { }

  ngOnInit() {
    if (this.formError && this.errorControlName?.length > 0) {
      const control = this.formError.get(this.errorControlName);
      if (!control) {
        console.error(`Could not find control with a name '${this.errorControlName}' in FormErrorComponent`);
        return;
      }

      this.sub = control.statusChanges.subscribe(status => {
        if (control.dirty) {
          this.errors$.next(control.errors);
        }
      });
    } else {
      console.error('depot-form-error does not have required fields');
    }
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  public mapError(key: string, validatorOptions: any) {
    // if (validatorOptions === null || validatorOptions === undefined) {
    //   validatorOptions = {};
    // }
    switch (key) {
      case 'min':
        return 'Value cannot be less than ' + validatorOptions?.min;

      case 'minlength':
        return 'Value length cannot be less than ' + validatorOptions?.requiredLength;

      case 'max':
        return 'Value cannot be more than ' + validatorOptions?.max;

      case 'maxlength':
        return 'Value length cannot be more than ' + validatorOptions?.requiredLength;

      case 'required':
        // validatorOptions = {required: boolean}
        return 'Value is required';

      case 'matDatepickerMin':
        // min is a Date object; validatorOptions = {min:Date, actual:Date}
        return 'Value must be after ' + format(validatorOptions?.min, 'MM/dd/yyyy');

      case 'matDatepickerMax':
        // max is a Date object; validatorOptions = {min:Date, actual:Date}
        return 'Value must be before ' + format(validatorOptions?.max, 'MM/dd/yyyy');

      case 'matDatepickerParse':
        // validatorOptions = {text:string} where text is the value of the invalid input
        return `'${validatorOptions.text}' is not a valid date`;

      default:
        console.log(validatorOptions);
        return validatorOptions;
      // return validatorOptions;
    }
  }

}
