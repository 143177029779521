import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { convertNestedDateToString } from '@depot/@common';
import { IWeightTicketFilters } from '@depot/@data';
import {
  IDealerReturnWeightTicket,
  IPagedResults
} from '@depot/custom';
import { environment } from '@env';

import { compare } from 'fast-json-patch';

@Injectable({
  providedIn: 'root'
})
export class WeightTicketRepositoryService {

  constructor(private httpClient: HttpClient) { }

  public searchWeightTickets(filters: IWeightTicketFilters) {
    const url = environment.get_endpoint(`WeightTicket`);
    return this.httpClient.get<IPagedResults<IDealerReturnWeightTicket>>(url, { params: <any>filters });
  }


  public GetWeightTicketsByDealerNumber(dealerNumber: string) {
    const url = environment.get_endpoint(`WeightTicket/GetWeightTicketsByDealerNumber/${dealerNumber}`);

    return this.httpClient.get<IDealerReturnWeightTicket[]>(url);
  }


  public getWeightTicket(id: number) {
    const url = environment.get_endpoint(`WeightTicket/${id}`);
    return this.httpClient.get<IDealerReturnWeightTicket>(url);
  }

  public saveWeightTicket(dealerReturn: Partial<IDealerReturnWeightTicket>, originalDealerReturn: Partial<IDealerReturnWeightTicket> = null) {
    convertNestedDateToString(dealerReturn);
    convertNestedDateToString(originalDealerReturn);

    const patch = compare(originalDealerReturn, dealerReturn);
    if (patch.every(x => x.path.toLowerCase().includes('rowversion'))) {
      patch.splice(0);
    }

    const url = environment.get_endpoint(`WeightTicket/${dealerReturn.id}`);
    return this.httpClient.patch<IDealerReturnWeightTicket>(url, patch);
  }

}
