import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IPricingSearch } from '@depot/@data';
import { IPagedResults, IPricing } from '@depot/custom';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class PricingRepositoryService {
  constructor(private httpClient: HttpClient) { }

  public searchPricings(filters: IPricingSearch) {
    const url = environment.get_endpoint('pricing');

    return this.httpClient.get<IPagedResults<IPricing>>(url, { params: <any>filters });
  }

  public getPricing(partLine: string, partNumber: string) {
    const url = environment.get_endpoint(`pricing/${partLine}/${partNumber}`);
    return this.httpClient.get<IPricing>(url);
  }
  public savePricing(partLine: string, partNumber: string, data: IPricing) {
    const url = environment.get_endpoint(`pricing/${partLine}/${partNumber}`);
    return this.httpClient.put<IPricing>(url, data);
  }
}
