import { Pipe, PipeTransform } from '@angular/core';

import { unescape } from 'underscore';

@Pipe({
  name: 'htmldecode',
  pure: true
})
export class HtmlDecodePipe implements PipeTransform {

  transform(value: string): string {
    return unescape(value);
  }

}
