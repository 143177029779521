import { Pipe, PipeTransform } from '@angular/core';

type compareOptions = 'eq' | 'lt' | 'lte' | 'neq' | 'gt' | 'gte';

@Pipe({
  name: 'filter',
  pure: true
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], attr: string, compare: compareOptions, value: any): any {
    if (!items) {
      return;
    }
    if (compare === 'eq') {
      return items.filter(x => x[attr] === value);
    } else {
      throw new Error('No value selected for compare in Depot code');
    }


  }

}
