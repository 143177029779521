import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IEmailLogSearch } from '@depot/@data';
import { IEmailLog, INewEmailModel, IPagedResults } from '@depot/custom';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class EmailLogRepositoryService {

  constructor(private httpClient: HttpClient) { }

  public sendEmail(emailModel: INewEmailModel) {
    const url = environment.get_endpoint('emaillog');

    return this.httpClient.post<INewEmailModel>(url, emailModel);
  }

  public getEmailLogs(filters: IEmailLogSearch) {
    const url = environment.get_endpoint('emaillog');

    return this.httpClient.get<IPagedResults<IEmailLog>>(url, { params: <any>filters });
  }


  public getEmailLog(id: number) {
    const url = environment.get_endpoint(`emaillog/${id}`);

    return this.httpClient.get<IEmailLog>(url);

  }

}
