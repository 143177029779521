import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'format',
  pure: true
})
export class FormatPipe implements PipeTransform {

  transform(value: any, format: 'phone', args?: any): any {
    if (format === 'phone') {
      return this.formatPhone(value);
    } else if (format === 'zip') {
      return this.formatZip(value);
    }
    throw new Error(`FormatPipe does not have a format '${format}'`);

  }

  private formatPhone(input: any) {
    const newVal = input.replace(/\D/g, '');

    if (newVal.length === 0) {
      return '';
    } else if (newVal.length === 7) {
      return newVal.replace(/^(\d{0,3})(\d{0,3})/, '$1-$2');
    } else if (newVal.length === 10) {
      return newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
    } else if (newVal.length > 10) {
      return newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})(\d{0})/, '($1) $2-$3 ext. $4');
    }
    return newVal;
  }

  private formatZip(input: any) {
    const newVal = input.replace(/\D/g, '');
    if (newVal.length === 0) {
      return '';
    } else if (newVal.length === 5) {
      return newVal;
    } else if (newVal.length > 5) {
      return newVal.replace(/^(\d{0,5})(\d{0})/, '$1-$2');

    }

    return newVal;
  }

}
