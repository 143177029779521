export class Json2Table {
  private static buildArray(jsonObj: any) {
    const tableElem = document.createElement('table');
    const stateObj: { empty?: number } = {};
    let rowElem: HTMLTableRowElement,
      cellElem: HTMLTableCellElement,
      isArrayHeader = false,
      isEmptyHeader = false,
      cellIndex = -1;
    if (jsonObj.length === 0) {
      return document.createElement('div');
    }
    rowElem = tableElem.insertRow(-1);
    for (let index = 0; index < jsonObj.length; index++) {
      if ('object' !== typeof jsonObj[index] || this.isArray(jsonObj[index])) {
        if ('object' === typeof jsonObj[index] && this.isArray(jsonObj[index])) {
          cellElem = rowElem.insertCell(cellIndex);
          cellElem.colSpan = 2;
          cellElem.classList.add('td_span', 'td_head');

          rowElem.insertAdjacentHTML('afterend', this.buildArray(jsonObj[index]).innerHTML);
          isArrayHeader = true;
        } else if (isEmptyHeader) {
          cellIndex += 1;
          isEmptyHeader = true;
          cellElem = rowElem.insertCell(cellIndex);
          stateObj.empty = cellIndex;
          cellElem.classList.add('td_head');
          cellElem.innerHTML = '&nbsp;';
        }
      } else {
        for (const key1 in jsonObj[index]) {
          if (jsonObj[index].hasOwnProperty(key1)) {
            const key2 = '-' + key1;
            if (!(key2 in stateObj)) {
              isArrayHeader = true;
              cellIndex += 1;
              cellElem = rowElem.insertCell(cellIndex);
              stateObj[key2] = cellIndex;
              cellElem.classList.add('td_head');
              cellElem.innerHTML = this.encodeText(key1);
            }
          }
        }
      }
    }
    if (!isArrayHeader) {
      tableElem.deleteRow(0);
    }
    const maxRows = cellIndex + 1;
    for (let index = 0; index < jsonObj.length; index++) {
      rowElem = tableElem.insertRow(-1);
      if ('object' !== typeof jsonObj[index] || this.isArray(jsonObj[index])) {
        if ('object' === typeof jsonObj[index] && this.isArray(jsonObj[index])) {
          cellIndex = stateObj.empty;
          for (let index2 = 0; index2 < maxRows; index2++) {
            cellElem = rowElem.insertCell(index2);
            if (index2 === cellIndex) {
              rowElem.insertAdjacentHTML('afterend', this.buildArray(jsonObj[index2]).innerHTML);
            } else {
              cellElem.innerHTML = '<div> </div>';
            }
          }
        } else {
          cellIndex = stateObj.empty;
          for (let index2 = 0; index2 < maxRows; index2++) {
            cellElem = rowElem.insertCell(index2);
            const rowText = index2 === cellIndex ? jsonObj[index2] : ' ';
            cellElem.innerHTML = '<div>' + this.encodeText(rowText) + '</div>';
          }
        }
      } else {
        for (let i = 0; i < maxRows; i++) {
          cellElem = rowElem.insertCell(i);
          cellElem.innerHTML = '<div>&nbsp;</div>';
        }
        for (const key in jsonObj[index]) {
          if (jsonObj[index].hasOwnProperty(key)) {
            const obj = jsonObj[index];
            const key2 = '-' + key;
            const h = stateObj[key2];
            const cell = rowElem.cells[h];
            if ('object' !== typeof obj[key] || this.isArray(obj[key])) {
              if ('object' === typeof obj[key] && this.isArray(obj[key])) {
                rowElem.insertAdjacentHTML('afterend', this.buildArray(obj[key]).innerHTML);
              } else {
                cell.innerHTML = '<div>' + this.encodeText(obj[key]) + '</div>';
              }

            } else {
              rowElem.insertAdjacentHTML('afterend', this.buildArray(obj[key]).innerHTML);
            }


          }
        }
      }
    }
    return tableElem;
  }

  private static encodeText(text: string) {
    if (!text || (typeof text === 'string' && text.trim().length === 0)) {
      return '';
    }
    if (typeof text === 'string' && text.includes('class="st-')) {
      return text;
    }
    const ele = document.createElement('div');
    ele.append(document.createTextNode(text));

    return `<pre>${ele.innerHTML}<pre>`;
  }

  private static isArray(a) {
    return '[object Array]' === Object.prototype.toString.call(a);
  }

  public static buildTable(obj, parentSpan: number = 1) {
    const tableElem = document.createElement('table');
    let rowElem: HTMLTableRowElement,
      cellElem: HTMLTableCellElement;
    if (this.isArray(obj)) {
      return this.buildArray(obj);
    }
    for (const key in obj) {

      if ('object' !== typeof obj[key] || this.isArray(obj[key])) {
        if ('object' === typeof obj[key] && this.isArray(obj[key])) {
          rowElem = tableElem.insertRow(-1);
          cellElem = rowElem.insertCell(-1);
          cellElem.colSpan = 2;
          cellElem.classList.add('td_span', 'td_head', 'td_head_array');
          cellElem.innerHTML = `${this.encodeText(key)}`;
          rowElem.insertAdjacentHTML('afterend', this.buildArray(obj[key]).innerHTML);
        } else {
          rowElem = tableElem.insertRow(-1);
          cellElem = rowElem.insertCell(-1);
          cellElem.innerHTML = '<div class="td_head">' + this.encodeText(key) + '</div>';
          cellElem = rowElem.insertCell(-1);
          cellElem.innerHTML = '<div class="td_row_even">' + this.encodeText(obj[key]) + '</div>';
        }

      } else {
        rowElem = tableElem.insertRow(-1);
        cellElem = rowElem.insertCell(-1);
        cellElem.colSpan = 2;
        cellElem.classList.add('td_span', 'td_head');
        cellElem.innerHTML = this.encodeText(key);
        rowElem.insertAdjacentHTML('afterend', this.buildTable(obj[key]).innerHTML);

      }
    }
    return tableElem;
  }

}
