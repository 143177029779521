import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { AfterViewInit, Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[depotDecimal]'
})
export class DecimalDirective implements AfterViewInit {

  private lastValue: string = null;
  private format = '1.2-2';
  private isInitialValue = false;

  constructor(
    public ref: ElementRef<HTMLInputElement>,
    private renderer: Renderer2,
    private currencyPipe: CurrencyPipe,
    private decimalPipe: DecimalPipe,
  ) { }

  ngAfterViewInit() {
    if (this.ref?.nativeElement?.value?.length >= 1) {
      this.ngModelChange(this.ref.nativeElement.value);
    }

  }

  @HostListener('blur', ['$event'])
  onBlur($event: any) {

    $event.preventDefault();
    $event.target.value = this.decimalPipe.transform(($event.target.value || ''), this.format)?.replace(/,/g, '');
    // $event.target.value = this.currencyPipe.transform(($event.target.value || ''), '', '', this.format).replace(/,/g, '');

    // if (this.lastValue === null || (this.lastValue && $event.target.value.length > 0 && this.lastValue !== $event.target.value)) {
    //   this.lastValue = $event.target.value;
    this.renderer.setValue(this.ref.nativeElement, this.lastValue);
    // Propagation
    // const evt = new Event('blur', {
    //   bubbles: false,
    //   cancelable: true,
    // });
    // // const evt = document.createEvent('HTMLEvents');
    // // evt.initEvent('change', false, true);
    // $event.target.dispatchEvent(evt);
  }
  // }
  // HTMLInputElement
  // @HostListener('change', ['$event'])
  // onChange($event: any) {
  //   console.log('change', $event);
  //   if (this.isInitialValue) {
  //     return;
  //   }
  //   this.isInitialValue = true;
  //   this.onBlur($event);
  // }

  @HostListener('ngModelChange', ['$event'])
  ngModelChange($event: string) {

    if (this.ref.nativeElement === document.activeElement) {
      return;
    }
    // $event = this.decimalPipe.transform(($event || ''), this.format)?.replace(/,/g, '');
    // $event.target.value = this.currencyPipe.transform(($event.target.value || ''), '', '', this.format).replace(/,/g, '');

    // if (this.lastValue === null || (this.lastValue && $event.length > 0 && this.lastValue !== $event)) {
    // this.lastValue = this.decimalPipe.transform(($event || ''), this.format)?.replace(/,/g, '');
    // console.log('ngModelChange2', this.lastValue);
    // this.ref.nativeElement.value = this.lastValue;
    // this.renderer.setValue(this.ref.nativeElement, this.lastValue);
    // Propagation
    // const evt = document.createEvent('HTMLEvents');
    // evt.initEvent('change', false, true);
    // if (!this.isInitialValue) {
    const evt = new Event('blur', {
      bubbles: false,
      cancelable: true,
    });
    setTimeout(() => {

      this.ref.nativeElement.dispatchEvent(evt);
    });
    // }
    // }
  }
}
