import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { convertNestedDateToString } from '@depot/@common';
import { IErrorSearch } from '@depot/@data';
import { IJsonLog, IPagedResults } from '@depot/custom';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class ErrorRepositoryService {

  constructor(private httpClient: HttpClient) { }

  public searchErrors(filters: IErrorSearch) {
    const url = environment.get_endpoint(`logger`);

    return this.httpClient.get<IPagedResults<IJsonLog>>(url, { params: <any>filters });

  }

  public getDashboardData(params: { startDate: Date; endDate: Date }) {
    const url = environment.get_endpoint(`logger/GetDashboardData`);
    // const params = new HttpParams()
    //   .append('startDate', <any>startDate)
    //   .append('endDate', <any>endDate);
    convertNestedDateToString(params);
    return this.httpClient.get<IJsonLog[]>(url, { params: <any>params });

  }

  public getApplicationList() {
    const url = environment.get_endpoint(`logger/GetApplicationList`);

    return this.httpClient.get<string[]>(url);

  }
}
