<!-- cdkDropList -->
<!-- (cdkDropListDropped)="changeImage($event)" -->

<div class="image-row tw-flex tw-flex-wrap">
  <!-- <depot-image-thumbs [partImages]=""
  (edit)="onEditImage($event.image)"
  (delete)="removeFormItem($event.index,row.controls['partImages'])"></depot-image-thumbs> -->
  <div *ngFor="let img of (visualImages$ | async) as images; let i=index;"
       class="lightbox-item">
    <!-- cdkDrag -->
    <img class="part-img"
         loading="lazy"
         [matTooltip]="'Part ' + img.partLine + img.partNumber"
         matTooltipPosition="above"
         [matTooltipDisabled]="!ShowPartTooltip"
         [ngClass]="img.isExternal ? 'img-external': 'img-internal'"
         [class.img-selected]="(SelectedImages|async).indexOf(img) !== -1"
         [class.no-borders]="!ShowBorders"
         (click)="openLightbox(images, i)"
         [src]="img.imagePath" />
    <div class="image-controls">

      @if(ShowEditOverride===true || ParentId === img[ParentIdKey]){

      <button type="button"
              (click)="onEditImage(img)"
              matTooltip="Edit Image"
              matTooltipPosition="above"
              mat-icon-button
              [tabIndex]="-1">
        <span class="fa fa-edit"></span>
      </button>

      <button type="button"
              (click)="removeFormImage(i, images)"
              matTooltip="Delete Image"
              matTooltipPosition="above"
              mat-icon-button
              [tabIndex]="-1">
        <span class="fa fa-trash"></span>
      </button>

      <button type="button"
              (click)="openLink(img)"
              *ngIf="ShowSourceLink===true"
              matTooltip="Open Link"
              matTooltipPosition="above"
              mat-icon-button
              [tabIndex]="-1">
        <span class="fa fa-external-link-alt"></span>
      </button>

      }
      <button type="button"
              (click)="toggleImage(img)"
              *ngIf="ShowImageToggle===true"
              [matTooltip]="(SelectedImages|async).indexOf(img) == -1 ? 'Add Image': 'Remove Image'"
              matTooltipPosition="above"
              mat-icon-button
              [tabIndex]="-1">
        <span class="fa "
              [ngClass]="(SelectedImages|async).indexOf(img) == -1 ?'fa-plus':'fa-minus'"></span>
      </button>
    </div>
  </div>

</div>
