import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IPriceBookSearch } from '@depot/@data';
import { IPagedResults, IPriceBookLine, IPriceBookPage, IPriceBookPageGroupModel } from '@depot/custom';
import { environment } from '@env';

import { catchError, Observable, shareReplay, throwError } from 'rxjs';

import { format } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class PriceBookRepositoryService {
  private priceBookCache: { [key: string]: Observable<IPriceBookPage> } = {};
  constructor(private httpClient: HttpClient) { }

  public searchPriceBookLines(filters: IPriceBookSearch) {
    const url = environment.get_endpoint('pricebook/lines');

    return this.httpClient.get<IPagedResults<IPriceBookLine>>(url, { params: <any>filters });
  }

  public searchPriceBookPages(filters: IPriceBookSearch) {
    const url = environment.get_endpoint('pricebook/page');

    return this.httpClient.get<IPagedResults<IPriceBookPageGroupModel>>(url, { params: <any>filters });
  }

  public getPriceBookLine(line: string, manufacturerNumber: string, fuzzySearch: boolean, verifiedOnly: boolean, bustCache: boolean) {
    const url = environment.get_endpoint(`pricebook/line/${line}/${manufacturerNumber}?fuzzySearch=${fuzzySearch}&verifiedOnly=${verifiedOnly}`);
    const headers = new HttpHeaders();
    if (bustCache === true) {

      // url += '?date=' + (new Date().getTime());
      headers.set('Cache-Control', 'must-revalidate')
        // .set('Pragma', 'no-cache')
        .set('Expires', new Date().toUTCString())
        .set('If-Modified-Since', '0');
    }
    return this.httpClient.get<IPriceBookLine[]>(url, { headers });
  }

  public getPriceBookPages(make: string, date: string) {
    const url = environment.get_endpoint(`pricebook/page/${make}/${date}`);
    return this.httpClient.get<IPriceBookPage[]>(url);
  }

  public getPriceBookLines(line: string, manufacturerNumber: string[]) {
    const url = environment.get_endpoint(`pricebook/line/${line}`);
    return this.httpClient.post<IPriceBookLine[]>(url, manufacturerNumber);
  }

  public getPriceBookLineById(id: string) {
    const url = environment.get_endpoint(`pricebook/line/${id}`);
    return this.httpClient.get<IPriceBookLine>(url);
  }

  public getPriceBookPageMaxLine(make: string, date: Date) {
    const url = environment.get_endpoint(`pricebook/page/MaxPage/${make}/${format(date, 'yyyy-MM-dd')}`);
    return this.httpClient.get<{ maxPage: string }>(url);
  }

  public savePriceBookLine(priceBookLine: IPriceBookLine) {
    const url = environment.get_endpoint(`pricebook/line/${priceBookLine.id}`);
    return this.httpClient.put<IPriceBookLine>(url, priceBookLine);
  }

  public deletePriceBookLineById(id: string) {
    const url = environment.get_endpoint(`pricebook/line/${id}`);
    return this.httpClient.delete<IPriceBookLine>(url);
  }

  // public getPriceBookLineByLineAndNumbers(line: string, manufacturerNumber: string[]) {
  //   const url = environment.get_endpoint(`pricebook/${line}/${encodeURIComponent(JSON.stringify(manufacturerNumber))}`);
  //   return this.httpClient.get<IPriceBookLine[]>(url);
  // }

  public getCurrentPriceBook(lineType: string) {
    if (this.priceBookCache[lineType]) {
      return this.priceBookCache[lineType];
    }
    const url = environment.get_endpoint(`pricebook/page/CurrentPriceBook/${encodeURIComponent(lineType)}`);
    this.priceBookCache[lineType] = this.httpClient.get<IPriceBookPage>(url).pipe(
      shareReplay(1),
      catchError((err) => {
        delete this.priceBookCache[lineType];
        return throwError(() => err);
      })

    );
    return this.priceBookCache[lineType];
  }

  public getAvailableMakes() {

    const url = environment.get_endpoint(`pricebook/page/GetAvailableMakes`);
    return this.httpClient.get<{ make: string; partLine: string }[]>(url);
  }
}
