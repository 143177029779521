import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { HelperService } from '@depot/@common';

import { Observable } from 'rxjs';

import { isFunction } from 'underscore';

export interface CanComponentDeactivate {
  form?: UntypedFormGroup;
  canDeactivate?: () => Observable<boolean> | Promise<boolean> | boolean;
}

/**
 * If you want to override the default functionality implement the CanComponentDeactivate interface
 * To simply get the default functionality add the FormChangesGuard to your route configuration
 */
@Injectable({
  providedIn: 'root'
})
export class FormChangesGuard {

  constructor(
    private helper: HelperService,
  ) {
  }

  canDeactivate(component: CanComponentDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot) {
    if (!component) {
      return true;
    }
    if (isFunction(component.canDeactivate)) {
      return component.canDeactivate();
    } else if (component.form) {
      if (component.form && component.form.dirty) {
        return this.helper.confirmDialog('You have pending changes, do you want to leave and lose those changes?');
      }
    }
    return true;


  }


}
