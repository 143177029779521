import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest, HttpResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService, convertNestedToDate, HelperService } from '@depot/@common';

import { catchError, Observable, tap, throwError } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private auth: AuthService,
    private router: Router,
    private helper: HelperService,
  ) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // try {
    //   throw new Error('test');

    // } catch (err) {
    //   console.log(err.stack);
    //   this.helper.logger.error('error3 happened', err);
    // }
    const params = this.cleanParams(request.params);
    const authReq = request.clone({ params: params, withCredentials: true /* environment.xhrWithCredentials*/ });
    return next.handle(authReq).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          convertNestedToDate(event.body);
        }
      }),
      catchError(error => this.errorHandler(error))
    );
  }

  public errorHandler(error: HttpErrorResponse) {
    const returnUrl = this.helper.getQueryParam('returnUrl', this.router.url);
    if (error.status === HttpStatusCode.Unauthorized) {
      if (error?.error?.errors?.length > 0) {
        // this happens when a path is requested but the user isn't authorized
        return throwError(() => error.error);
      }

      if (this.auth.user !== undefined) {
        this.auth.user = null;
        this.router.navigate(['/login'], {
          queryParams: {
            returnUrl: returnUrl ?? this.router.url
          }
        });
      }

      const err = new Error('Session has expired.');
      (<any>error).status = HttpStatusCode.Unauthorized;
      return throwError(() => error);
    }
    if (error.status === HttpStatusCode.ServiceUnavailable) {
      this.router.navigate(['/app-offline'], {
        queryParams: {
          returnUrl: returnUrl ?? this.router.url
        }
      });
      (<any>error).status = HttpStatusCode.ServiceUnavailable;
      return throwError(() => error);
    }
    if (error.status === HttpStatusCode.NotModified) {
      (<any>error).error = 'No changes detected';

      return throwError(() => error);
    }
    // if (error.status === HttpStatusCode.BadRequest && error.error) {
    //   return throwError(() => error.error);
    // }
    return throwError(() => error);
  }

  private cleanParams(params: HttpParams) {
    if (params) {
      for (const key of params.keys()) {
        const value = params.get(key) as any;
        // check for the literal 'null' because Angular 14 maps all values to a string in the HttpParams constructor
        if (value === null || value === undefined || value === 'null') {
          params = params.set(key, '');
        } else if (value instanceof Date) {
          params = params.set(key, value.toDateString() + ' ' + value.toLocaleTimeString());

        } else if (this.isDateString(value)) {
          const dateValue = new Date(value);
          params = params.set(key, dateValue.toDateString() + ' ' + dateValue.toLocaleTimeString());
        }

        // params = params.set(key, encodeURIComponent(value));
      }
    }
    return params;
  }

  private isDateString(input: string) {
    return new Date(input).toString() === input;
  }

}
