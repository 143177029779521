import { Injectable } from '@angular/core';

import { LogLevels } from '@depot/@common';

@Injectable({
  providedIn: 'root'
})
export class WindowWrapper extends Window {
  // The time the page started to load
  public startTime: Date;
  // The time when window.ready fired
  public loadTime: Date | null;
  // The time the error occurred on the page
  // public failTime: Date | null;
  // The logged in user
  public user: string | null;
  // The logged in user
  public logLevel: LogLevels | null;
  // Intl.DateTimeFormat().resolvedOptions().timeZone
  public timeZone: string;
}
