import { parseISO } from 'date-fns';

const iso8601 = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/;

function isIso8601(value: string | null | undefined) {
  return typeof value !== 'string' ? false : iso8601.test(value);
}

export function convertNestedToDate(body: object | null | undefined): void {
  if (body === null || body === undefined) {
    return;
  }

  if (typeof body !== 'object') {
    return;
  }

  for (const key of Object.keys(body)) {
    const value = body[key];
    if (isIso8601(value)) {
      body[key] = parseISO(value);

    } else if (typeof value === 'object') {
      convertNestedToDate(value);
    }
  }
}

export function convertNestedDateToString(body: object | null | undefined): void {
  if (body === null || body === undefined) {
    return;
  }

  if (typeof body !== 'object') {
    return;
  }

  for (const key of Object.keys(body)) {
    const value = body[key];
    if (value instanceof Date) {
      body[key] = value.toDateString() + ' ' + value.toLocaleTimeString();

    } else if (typeof value === 'object') {
      convertNestedDateToString(value);
    }
  }
}

