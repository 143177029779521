import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IOrderSearch } from '@depot/@data';
import { IOrder, IPagedResults } from '@depot/custom';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class OrderRepositoryService {

  constructor(private httpClient: HttpClient) { }

  public searchOrders(filters: IOrderSearch) {
    const url = environment.get_endpoint('order');

    return this.httpClient.get<IPagedResults<IOrder>>(url, { params: <any>filters });
  }


  public getOrderById(id: number) {
    const url = environment.get_endpoint(`order/${id}`);

    return this.httpClient.get<IOrder>(url);

  }

  public getOrderByPart(line: string, number: string) {
    const url = environment.get_endpoint(`order/${line}/${number}`);

    return this.httpClient.get<IOrder[]>(url);

  }

  public saveOrder(order: IOrder) {
    const url = environment.get_endpoint(`order/${order.id}`);

    return this.httpClient.put<IOrder>(url, order);

  }

}
