import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IDealerSearch } from '@depot/@data';
import { IDealer, IDealerData, IDocument, IPagedResults } from '@depot/custom';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class DealerRepositoryService {

  constructor(private httpClient: HttpClient) { }

  public searchDealers(filters: IDealerSearch) {
    const url = environment.get_endpoint('dealer');

    return this.httpClient.get<IPagedResults<IDealer>>(url, { params: <any>filters });
  }


  public getDealer(dealerNumber: string) {
    const url = environment.get_endpoint(`dealer/${dealerNumber}`);

    return this.httpClient.get<IDealer>(url);

  }

  public getDealerData(dealerNumber: string) {
    const url = environment.get_endpoint(`dealer/${dealerNumber}/data`);

    return this.httpClient.get<IDealerData>(url);

  }

  public getDocuments(dealerNumber: string) {
    const url = environment.get_endpoint(`dealer/${dealerNumber}/documents`);
    return this.httpClient.get<IDocument[]>(url);

  }

}
