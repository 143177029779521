import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'depot-iframe-dialog',
  template: `
  <div class="fixActionRow">
    <div mat-dialog-content>
      <iframe [src]="url"
          style="height: 85vh; width: 99%;"></iframe>
    </div>
  <div class="spacer"></div>
    <div mat-dialog-actions>
      <a mat-raised-button
        *ngIf="data.fileName"
        color="secondary"
        [href]="url"
        [download]="data.fileName">
          Download File
      </a>
      <span class="dp-flex-space"></span>
      <button mat-raised-button color="primary" mat-dialog-close="">Done</button>
    </div>
</div>
  `,
  styles: [`
    @use 'styles.shared' as dp;
    @include dp.dp-dialog-fix();

  `]
})
export class IFrameDialogComponent {
  public url: SafeHtml;
  constructor(
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: {
      url?: string;
      fileName?: string;
    }) {

    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(data.url);

  }

}
