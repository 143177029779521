import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'depot-confirmation-dialog',
  template: `
  <h1 mat-dialog-title>{{Title}}</h1>
  <div mat-dialog-content>
  {{Message}}
  </div>
  <div mat-dialog-actions
    align="center">
    <button mat-raised-button (click)="dialogRef.close(false)">No</button>
    <button mat-raised-button color="primary" (click)="dialogRef.close(true)">Yes</button>
  </div>
  `,
})
export class ConfirmationDialogComponent {
  public Title = '';
  public Message: SafeHtml = '';

  constructor(
    private sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      title?: string;
      message?: string;
    }) {

    data = data || {};
    this.Title = data.title || 'Please Confirm';
    this.Message = data.message || 'Are you sure you want to do this?';
  }
}
