import { Injectable } from '@angular/core';

import { ILocalSettings } from '@depot/custom';

import { BehaviorSubject } from 'rxjs';

export enum DepotTables {
  settings = 'settings'
}
const currentVersion = 3;
@Injectable({
  providedIn: 'root'
})
export class DepotContextService {

  private initializeState: 'uninitialized' | 'closing' | 'initialized' | 'error' =
    'uninitialized';
  private _db: IDBDatabase;

  async getObjectStore(name: string): Promise<IDBObjectStore> {
    if (this.initializeState !== 'initialized') {
      await this.initAsync();
    }

    if (!this._db) {
      throw Error(
        `Context is in an ${this.initializeState} state.  Run DepotContextService.initAsync() prior to calling other methods`
      );
    }
    const transaction = this._db.transaction(name, 'readwrite');

    return transaction.objectStore(name);
  }

  initAsync(): Promise<DepotContextService> {
    return new Promise((success, error) => {
      const request = indexedDB.open('depotcontext', currentVersion);
      request.onsuccess = () => {
        this.initializeState = 'initialized';
        this._db = request.result;

        success(this);
      };
      request.onerror = err => {
        this.initializeState = 'error';
        error((<any>err.target).error);
      };
      request.onupgradeneeded = ev => {
        this.runUpgrade(request, ev);
      };
    });
  }

  close() {
    if (this._db) {
      this.initializeState = 'closing';
      this._db.close();
    }

  }


  public getAllAsync<T>(sourceTable: DepotTables, query?: any) {
    return new Promise<T[]>(async (success, error) => {
      const source = await this.getObjectStore(sourceTable);
      const request = source.getAll(query);

      request.onsuccess = ev => {
        success((<IDBRequest<T[]>>ev.target).result);
      };

      request.onerror = err => {
        error(err);
      };
    });
  }

  public getSettingAsync<T>(settingKey: string, userName: string) {
    return new Promise<T | null>(async (success, error) => {
      const source = await this.getObjectStore(DepotTables.settings);
      const request = source.get([settingKey, userName]);

      request.onsuccess = (ev) => {
        const dbRequest: IDBRequest<ILocalSettings<T>> = <any>ev.target;
        success(dbRequest?.result?.data);
      };

      request.onerror = err => {
        error(err);
      };
    });
  }

  public saveSettingAsync<T>(settingKey: string, userName: string, data: T) {
    return new Promise<string[]>(async (success, error) => {
      const source = await this.getObjectStore(DepotTables.settings);
      const request = source.put({
        id: settingKey,
        userName: userName,
        data: data
      });

      request.onsuccess = (ev) => {
        success(<any>(<IDBRequest<T>>ev.target).result);
      };

      request.onerror = err => {
        error(err);
      };
    });
  }


  public getUsageAsync() {
    return navigator.storage.estimate();
  }

  private getByIdAsync<T>(sourceTable: DepotTables, id: any) {
    return new Promise<T>(async (success, error) => {
      const source = await this.getObjectStore(sourceTable);
      const request = source.get(id);

      request.onsuccess = (ev) => {
        success((<IDBRequest<T>>ev.target).result);
      };

      request.onerror = err => {
        error(err);
      };
    });
  }

  private saveAsync<T>(sourceTable: DepotTables, data:
    { id: IDBValidKey; data: T } | { id: IDBValidKey; data: T }[]) {
    return new Promise(async (success, error) => {
      const source = await this.getObjectStore(sourceTable);

      if (Array.isArray(data)) {
        for (let i = 0; i < data.length; i++) {
          source.put(data[i], data[i].id);
        }

      } else {
        source.put(data, data.id);
      }

      source.transaction.oncomplete = ev => {
        const key = (<IDBOpenDBRequest>ev.target).result;
        success(key);
      };

      source.transaction.onerror = err => {
        error(err);
      };
    });
  }


  private runUpgrade(request: IDBOpenDBRequest, ev: IDBVersionChangeEvent) {
    const db = request.result;
    if (!db.objectStoreNames.contains(DepotTables.settings)) {
      db.createObjectStore(DepotTables.settings, {
        keyPath: 'id',
        autoIncrement: false
      });
    }
    if (ev.newVersion === 2) {
      db.deleteObjectStore(DepotTables.settings);
      db.createObjectStore(DepotTables.settings, {
        keyPath: ['id', 'username'],
        autoIncrement: false
      });
    }
    if (ev.newVersion === 3) {
      db.deleteObjectStore(DepotTables.settings);
      db.createObjectStore(DepotTables.settings, {
        keyPath: ['id', 'userName'],
        autoIncrement: false
      });
    }
  }

}
