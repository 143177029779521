import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@env';

import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GenericInfoRepositoryService {

  private usStateResponse$: Observable<Record<string, string>>;
  private canadaProvincesResponse$: Observable<Record<string, string>>;
  constructor(
    private http: HttpClient
  ) { }

  public UsStates() {
    const url = environment.get_endpoint('genericinfo/UsStates');
    if (!this.usStateResponse$) {
      this.usStateResponse$ = this.http.get<Record<string, string>>(url);
    }
    return this.usStateResponse$;
  }

  public CanadaProvinces() {
    if (!this.canadaProvincesResponse$) {
      const url = environment.get_endpoint('genericinfo/CanadaProvinces');
      this.canadaProvincesResponse$ = this.http.get<Record<string, string>>(url);
    }
    return this.canadaProvincesResponse$;

  }

}
