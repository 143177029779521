import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { HelperService, ImageService } from '@depot/@common';
import { PartRepositoryService } from '@depot/@data';
import { IPartImage } from '@depot/custom';

import { catchError, of } from 'rxjs';

import Cropper from 'cropperjs';

@Component({
  selector: 'depot-image-edit-dialog',
  templateUrl: './image-edit-dialog.component.html',
  // styleUrls: ['./image-edit-dialog.component.scss']
})
export class ImageEditDialogComponent implements AfterViewInit {

  public partImage: IPartImage | null;
  public cropper: Cropper;
  // public imageInfo = new Subject<Cropper.ImageData>();
  @ViewChild('imgSrc') imgSrc: ElementRef<HTMLImageElement>;
  constructor(
    private partRepo: PartRepositoryService,
    public dialogRef: MatDialogRef<ImageEditDialogComponent>,
    private helper: HelperService,
    private imageSvc: ImageService,
    // private zone: NgZone,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.partImage = data.partImage;
  }

  ngAfterViewInit() {

    this.cropper = new Cropper(this.imgSrc.nativeElement, {
      aspectRatio: ImageService.AspectRatio,
      autoCrop: false, // this starts the cropping once its loaded, not what we want
      viewMode: 1
    }).replace(this.data.imagePath);

    // this.cropper.replace(URL.createObjectURL(this.partImage.fileStream));
  }

  public setDragMode(mode: 'move' | 'crop') {
    this.cropper.setDragMode(mode as any);
  }

  public save() {

    this.cropper.getCroppedCanvas({
      width: ImageService.ImageWidth,
      height: ImageService.ImageHeight
    }).toBlob(async (data) => {
      // this.partImage.fileStream = await new Response(data).arrayBuffer();
      if (this.partImage) {
        this.partImage.fileStream = await this.imageSvc.blobToBytesAsync(data);
        if (!this.partImage.sortOrder || isNaN(this.partImage.sortOrder)) {
          this.partImage.sortOrder = 0;
        }
        if (this.partImage.id > 0) {
          this.partRepo.saveImage(this.partImage)
            .pipe(catchError(() => {
              this.helper.showMessage('Error saving image', 'error');
              return of(null);
            }))
            .subscribe(x => {
              this.cropper.destroy();
              // this.zone.runGuarded(() => {
              this.dialogRef.close(x);

              // });

            });
        } else {
          this.partImage.imagePath = await this.imageSvc.blobToBase64Async(data);
          this.cropper.destroy();
          // this.zone.runGuarded(() => {
          this.dialogRef.close(this.partImage);

          // });
        }
      } else {
        this.dialogRef.close(data);
      }

    }, 'image/jpeg');
  }

}
