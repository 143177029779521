import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';

import { IPart, IPartImage } from '@depot/custom';

import { BehaviorSubject } from 'rxjs';

import { unique } from 'underscore';

export class DepotValidators {
  static requiredPart(partRow: UntypedFormControl): any {
    if (partRow.errors && !partRow.errors.missing) {
      return;
    }

    // const part = part.get('part$');
    if (partRow && (partRow.value as BehaviorSubject<IPart>).value) {
      return null;
    } else {
      return { missing: 'No part info available' };
    }
  }

  /**
 * @description Validate that a single location doesn't have more than
 * the allowed parts based on maxRows value
 * @param maxRows The maximum unique rows allowed with a part
 */
  static maxPerLocation(maxRows: number) {
    const notValidLocations = [
      'SCRAP',
      'DAMAGED',
      'NOTFOUND',
      'SOLD',
      'MI PART',
      'JNB',
    ];
    return (control: AbstractControl) => {
      if (control.value && control.value.length > 0 && control.parent && control.parent.parent) {
        const rows = control.parent.parent.getRawValue() as { partLocation: string; partNumber: string }[];
        const currentLocationItems = rows.filter(x => x.partLocation === control.value && !notValidLocations.includes(x.partLocation));
        const currentCount = unique(currentLocationItems.map(x => x.partNumber)).length;
        if (currentCount >= (maxRows + 1)) {
          control.markAsTouched();
          return { maxPerLocation: `You can only put ${maxRows} parts in a single location` };
        }
      }
      return null;
    };

  }

  /**
  * @description Validate that the description is the same across all rows of the form
  */
  static nonUniqueDescriptions() {

    return (control: AbstractControl) => {
      if (control.value && control.value.length > 0 && control.parent && control.parent.parent) {
        const thisRow = control.parent.getRawValue() as { description: string; partNumber: string; partLine: string };

        // field > row > rows
        const controls = control.parent.parent.controls as AbstractControl[];
        const relevantRows = [];
        for (let i = 0; i < controls.length; i++) {
          const rowValue = controls[i].getRawValue();
          if (thisRow.partLine === rowValue.partLine &&
            thisRow.partNumber === rowValue.partNumber &&
            (thisRow.description || '').length > 0
          ) {
            relevantRows.push(controls[i]);
          }
        }

        if (relevantRows.length > 1 &&
          !relevantRows.every((x, i, arr) => arr[0].getRawValue().description === x.getRawValue().description)) {
          control.parent.markAsTouched();
          relevantRows.forEach(x => {
            x.markAsTouched();
            x.warnings = { nonUniqueDescriptions: `Multiple descriptions found` };
          });
          return null;
        } else {
          relevantRows.forEach(x => {
            x.warnings = null;
          });
        }
      }
      return null;
    };

  }

  /**
 * @description Validate that the description is the same across all rows of the form
 */
  static persistPartImages(row: AbstractControl) {
    // todo INFO Add non-BehaviorSubject to the logic
    if (row.value && row.parent && row.value.partImages$) {
      const thisRow = row.value as { partImages$: BehaviorSubject<IPartImage[]>; partNumber: string; partLine: string };

      // field > row > rows
      const controls = row.parent.controls as AbstractControl[];
      const relevantRows = [];
      for (let i = 0; i < controls.length; i++) {
        const rowValue = controls[i].getRawValue();
        if (thisRow.partLine === rowValue.partLine &&
          thisRow.partNumber === rowValue.partNumber
        ) {
          relevantRows.push(controls[i]);
        }
      }

      relevantRows.forEach(x => {
        (x as UntypedFormGroup).getRawValue().partImages$.next(structuredClone(thisRow.partImages$.getValue()));
        // x.markAsTouched();
        // x.warnings = { persistPartImages: `Image array sizes are different` };
      });
      return null;
      // } else {
      // relevantRows.forEach(x => {
      //   x.warnings = null;
      // });
      // }
    }
    return null;

  }

  static MatchPassword(passwordField = 'password', confirmPasswordField = 'confirmPassword'): ValidatorFn {

    return (control: AbstractControl) => {
      // if (!control.parent) {
      //   return;
      // }

      const password = control.get(passwordField);
      const confirmPassword = control.get(confirmPasswordField);
      if (!confirmPassword.valid && !confirmPassword.hasError('PasswordMisMatch')) {
        // there is already an error so ignore this validation
        return null;
      }

      if (password.value !== confirmPassword.value) {
        confirmPassword.setErrors({ PasswordMisMatch: 'Password and Confirm Password do not match' });
        return { PasswordMisMatch: 'Password and Confirm Password do not match' };
      } else {
        // if (confirmPassword.hasError('PasswordMisMatch')) {
        confirmPassword.setErrors(null);
        // }
        return null;
      }
    };
  }

  static Email(control: AbstractControl) {
    const emailRegex = /\S+@\S+\.\S+/;

    const email = control.value;
    if (!emailRegex.test(email)) {
      return { InvalidEmail: 'Email is not a valid email' };
    } else {
      return null;
    }
  }

  /**
   * @description when fieldToValidateField has a non-zero numeric value or is empty then fieldMarkedRequiredField
   * becomes required
   */
  static RequiredWhenSetNumeric(fieldToValidateField, fieldMarkedRequiredField) {
    return (control: AbstractControl) => {
      // if (!control.parent) {
      //   return;
      // }

      const fieldToValidate = control.get(fieldToValidateField);
      const fieldMarkedRequired = control.get(fieldMarkedRequiredField);

      if (!isNaN(fieldToValidate.value) && fieldToValidate.value > 0) {
        fieldMarkedRequired.setValidators([Validators.required]);
        // fieldMarkedRequired.markAsDirty();
        fieldMarkedRequired.updateValueAndValidity({ onlySelf: true });
        return null;
      } else {
        fieldMarkedRequired.clearValidators();
        fieldMarkedRequired.updateValueAndValidity({ onlySelf: true });
        return null;
      }
    };
  }

  /**
   * @description when fieldToValidateField has a value then fieldMarkedRequiredField
   * is not required
   */
  static RequiredUnless(fieldToValidateField: string, fieldMarkedRequiredField: string, noDefaultValidator: boolean = false) {
    return (control: AbstractControl) => {
      // if (!control.parent) {
      //   return;
      // }

      const fieldToValidate = control.get(fieldToValidateField);
      const fieldMarkedRequired = control.get(fieldMarkedRequiredField);

      if (fieldToValidate.value.length === 0) {
        if (fieldMarkedRequired.dirty || noDefaultValidator === false) {
          fieldMarkedRequired.setValidators([Validators.required]);

        }
        // fieldMarkedRequired.markAsDirty();
        fieldMarkedRequired.updateValueAndValidity({ onlySelf: true });
        return null;
      } else {
        fieldMarkedRequired.clearValidators();
        fieldMarkedRequired.updateValueAndValidity({ onlySelf: true });
        return null;
      }
    };
  }
}
