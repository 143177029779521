import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IPartsSearch } from '@depot/@data';
import { IPagedResults, IPart, IPartData, IPartImage, IProductLine } from '@depot/custom';
import { environment } from '@env';

import { map, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PartRepositoryService {
  private productLines: IProductLine[];
  constructor(
    private httpClient: HttpClient,
  ) { }

  public searchParts(filters: IPartsSearch) {
    const url = environment.get_endpoint('part');

    return this.httpClient.get<IPagedResults<IPart>>(url, { params: <any>filters });
  }

  public getPart(line: string, number: string) {
    const url = environment.get_endpoint(`part/${line}/${number}`);

    return this.httpClient.get<IPart>(url);
  }

  public getPartData(line: string, number: string) {
    const url = environment.get_endpoint(`part/partdata/${line}/${number}`);

    return this.httpClient.get<IPartData>(url)
      .pipe(map(data => {
        data.partImages = data.partImages ?? data.partImagePartials;
        return data;
      }));
  }

  public savePartData(partData: IPartData) {
    const url = environment.get_endpoint(`part/partdata/${partData.id}`);

    return this.httpClient.put<IPartData>(url, partData);
  }

  public getProductLines() {
    if (this.productLines) {
      return of(this.productLines);
    }
    const url = environment.get_endpoint('part/productLines');

    return this.httpClient.get<IProductLine[]>(url)
      .pipe(tap(x => this.productLines = x));
  }

  public saveImage(partImage: IPartImage) {
    const url = environment.get_endpoint(`part/partImage/${partImage.id}`);
    // const formData = new FormData();
    // for (const key in partImage) {
    //   if (partImage.hasOwnProperty(key) && key !== 'fileStream') {
    //     formData.append(key, partImage[key]);
    //   }
    // }
    // // formData.append('fileStream', partImage.fileStream, 'file.jpg');
    //
    // (<any>partImage).image = await this.blobToBase64(partImage.fileStream);
    // partImage.fileStream = null;
    // , { headers: {'content-type': 'multipart/form-data;boundary=----'} }
    return this.httpClient.put<IPartImage>(url, partImage);
  }

  public deleteImage(id: number) {
    const url = environment.get_endpoint(`part/partImage/${id}`);
    return this.httpClient.delete(url);
  }
}
