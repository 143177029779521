import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IRunDbLoadApp } from '@depot/@data';
import { ITableCounts } from '@depot/custom';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class AdminRepositoryService {
  constructor(private httpClient: HttpClient) { }

  public runDbLoadApp(model: IRunDbLoadApp) {
    const url = environment.get_endpoint(`admin/RunDbLoadApp`);
    const formData = new FormData();
    for (const key in model) {
      if (model.hasOwnProperty(key)) {
        formData.append(key, model[key]);
      }
    }
    return this.httpClient.post<void>(url, formData);

  }

  public getTableCounts() {
    const url = environment.get_endpoint(`admin/GetTableCounts`);

    return this.httpClient.get<ITableCounts>(url);
  }

  public getSettings() {
    const url = environment.get_endpoint(`admin/GetSettings`);

    return this.httpClient.get<{ key: string; value: string | number | boolean }[]>(url);
  }

}
