import { CdkDragDrop } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { HelperService } from '@depot/@common';
import { ImageEditDialogComponent } from '@depot/@components';
import { PartRepositoryService } from '@depot/@data';
import { IPartImage } from '@depot/custom';
import { environment } from '@env';

import { BehaviorSubject, catchError, first, map, Observable, of } from 'rxjs';

import { IAlbum, Lightbox, LightboxConfig } from 'ngx-lightbox';
import { sortBy, union, unique } from 'underscore';

@Component({
  selector: 'depot-image-row',
  templateUrl: './image-row.component.html',
  styleUrls: ['./image-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class ImageRowComponent implements OnInit, OnDestroy {


  @Input() public ImageArray: BehaviorSubject<IPartImage[]>;
  public visualImages$: Observable<IPartImage[]>;
  @Input() public ParentId: number | null = null;
  @Input() public ParentIdKey = '';
  @Input() public ShowEditOverride = false;
  @Input() public SortImages = false;
  @Input() public ShowSourceLink = false;
  @Input() public ShowPartTooltip = true;
  @Input() public ShowImageToggle = false;
  @Input() public ShowBorders = true;
  @Input() public SelectedImages = new BehaviorSubject<IPartImage[]>([]);
  @Output() public ImagesChanged = new EventEmitter<{ partLine: string; partNumber: string }>();
  // @Output() public SelectedImagesChange = new EventEmitter<IPartImage[]>();

  constructor(
    private dialog: MatDialog,
    public helper: HelperService,
    private lightbox: Lightbox,
    public sanitizer: DomSanitizer,
    private partRepo: PartRepositoryService,
    private router: Router,
  ) { }

  ngOnInit() {
    // const images = this.determineSort(this.ImageArray.value);
    this.visualImages$ = this.ImageArray.pipe(map((images: IPartImage[]) => {
      for (const img of images) {
        if (!img.hasOwnProperty('imagePath') || img.imagePath === undefined) {
          img.imagePath = environment.get_endpoint('part/partimage/' + img.fileStreamId);
        }
      }
      return this.determineSort(images);
    }));
    // this.ImageArray.next(images);
  }

  ngOnDestroy(): void {
  }

  private determineSort(images: IPartImage[]) {
    if (this.SortImages === true) {
      return sortBy(images, x => x.sortOrder);
    } else if (this.ParentId && this.ParentIdKey) {
      const currentImages = images.filter(x => x[this.ParentIdKey] === this.ParentId);
      return unique(union(currentImages, sortBy(images, x => x.sortOrder)), x => x.id);
    }
    return images;
  }

  public async removeFormImage(index: number, formArr: IPartImage[]) {

    if (await this.helper.confirmDialog('Are you sure you want to delete this item?')) {
      const row = formArr[index];
      if (row.id < 0) {

        formArr.splice(index, 1);
        this.ImageArray.next(formArr);
        if (this.ImagesChanged) {
          this.ImagesChanged.emit(null);
        }
        return;
      }
      this.partRepo.deleteImage(row.id)
        .pipe(catchError(() => {
          this.helper.showMessage('An error occurred while trying to remove image', 'error');
          return of(null);
        }))
        .subscribe(x => {
          this.helper.showMessage('Image deleted successfully', 'success');
          formArr.splice(index, 1);
          this.ImageArray.next(formArr);
          if (this.ImagesChanged) {
            this.ImagesChanged.emit(null);
          }

        });
    }

  }

  public async onEditImage(imgControl: IPartImage) {
    this.dialog.open(ImageEditDialogComponent, {
      data: {
        partImage: structuredClone(imgControl),
        imagePath: imgControl.imagePath,
      },
      disableClose: true,
      maxHeight: '100vh'
    })
      .afterClosed()
      .pipe(first())
      .subscribe((newPartImage: IPartImage) => {
        if (newPartImage) {


          let images = this.ImageArray.value;
          imgControl.isExternal = newPartImage.isExternal;
          imgControl.sortOrder = newPartImage.sortOrder;
          if (imgControl.partLine !== newPartImage.partLine ||
            imgControl.partNumber !== newPartImage.partNumber) {
            images = images.filter(x => x.id !== imgControl.id);
          }
          imgControl.partLine = newPartImage.partLine;
          imgControl.partNumber = newPartImage.partNumber;
          imgControl.imagePath = `${newPartImage.imagePath ?? environment.get_endpoint(`part/partimage/${newPartImage.fileStreamId}?cache=${new Date()}`)}`;
          // imgControl.patchValue({
          //   imagePath: ,
          //   isExternal: newPartImage.isExternal,
          //   sortOrder: newPartImage.sortOrder,
          // });
          if (this.ImagesChanged) {
            this.ImagesChanged.emit(imgControl);
          }
          if (this.ImagesChanged) {
            this.ImagesChanged.emit(null);
          }
          this.helper.showMessage('Image saved successful', 'success');
          this.ImageArray.next(images);
        }
      });
  }

  public openLightbox(images: any[], index: number) {
    const lightboxImages = images.map<IAlbum>(x => ({

      src: <any>this.sanitizer.bypassSecurityTrustUrl(x.imagePath),
      thumb: null,
      caption: x.partLine + x.partNumber,
      downloadUrl: null,
    })
    );
    this.lightbox.open(lightboxImages, index, <LightboxConfig>{
      fadeDuration: 0.25,
      resizeDuration: 0.25,
      wrapAround: true,
      showImageNumberLabel: true,

    });
  }

  public changeImage(event: CdkDragDrop<string[]>) {

  }

  public toggleImage(img: IPartImage) {
    let images = this.SelectedImages.getValue();
    if (images.some(x => x.id === img.id)) {
      images = images.filter(x => x.id !== img.id);
      this.SelectedImages.next([...images]);

    } else {
      this.SelectedImages.next([...images, img]);

    }
    // this.SelectedImages.emit(this.SelectedImages.getValue());
  }

  public openLink(img: IPartImage) {
    if (img.orderId) {
      this.router.navigate(['/order', img.orderId]);
    } else if (img.putAwayRowId) {
      this.router.navigate(['/putaway', img.putAwayRowId]);
    } else if (img.auditRowId) {
      this.router.navigate(['/audit', img.auditRowId]);
    }
  }

}
