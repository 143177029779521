import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sum',
  pure: true
})
export class SumPipe implements PipeTransform {

  transform(items: any[], attr: string): any {
    if (!items) {
      return;
    }
    if (attr) {
      return items.reduce((a, b) => a + b[attr], 0);

    }
    return items.reduce((a, b) => a + b, 0);
  }

}
