import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'map',
  pure: true
})
export class MapPipe implements PipeTransform {

  transform(items: any[], attr: string, shouldFlatten: boolean): any {
    if (!items) {
      return;
    }
    if (shouldFlatten === true) {
      return items.flatMap(x => x[attr]);
    }
    return items.map(x => x[attr]);


  }

}
