import packageInfo from '@pkg';

export const environment = {

  production: true,
  http_endpoint: `https://${location.host}/V1/api/`,
  app_endpoint: `https://${location.host}/app/`,
  version: packageInfo.version,
  get_endpoint(url: string) {
    const uri = new URL(url, environment.http_endpoint);
    return uri.href;
  }

};
