import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { WindowWrapper } from '@depot/@common';

import { parseJSON } from 'date-fns';
import { format } from 'date-fns-tz';

@Pipe({
  name: 'utcdate',
  pure: true
})
export class UtcDatePipe /* extends DatePipe */ implements PipeTransform {
  constructor(
    private window: WindowWrapper,
  ) { }

  transform(value: Date | string, dateFormat = 'short', timezone: string = this.window.timeZone, locale?: string): string {
    if (!value) {
      return '';
    }
    // the dates that are sent from the server are assumed to be local times however if they are passed
    // through this pipe we should change the assumption to be that they are UTC so we
    // remove the timezone and parse it as UTC
    dateFormat = this.mapFormatShorthand(dateFormat);
    const nonTimezoneTime = parseJSON(format(<any>value, 'yyyy-MM-dd HH:mm:ss'));
    return format(nonTimezoneTime, dateFormat, { timeZone: timezone });
  }


  private mapFormatShorthand(dateFormat: string) {
    if (dateFormat?.toLowerCase() === 'short') {
      return 'M/d/yy, h:mm a';
    } else if (dateFormat?.toLowerCase() === 'shortdate') {
      return 'M/d/yy';
    }

    return dateFormat;
  }

}
