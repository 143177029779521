{
  "name": "depot",
  "version": "1.10.17",
  "scripts": {
    "smtp": "maildev --smtp 25 --open",
    "v-patch": "npm version patch",
    "v-minor": "npm version minor",
    "zip-version": "powershell \"& '%ProgramFiles%\\7-Zip\\7z.exe' a -tzip ../publish/%npm_package_version%_$(git branch --show-current)_$(get-date -format 'yyyyMMddHHmm').zip ../publish/raw/\"",
    "api-copy": "xcopy \"./../../DepotParts.WebServices/publish\" \"./../publish/raw/api\" /E /I /y",
    "api-publish": "cd ../../DepotParts.WebServices && dotnet build -c Release /p:DeployOnBuild=true /p:Configuration=Release /p:PublishProfile=LocalFolderProfile",
    "api-server": "cd ../../DepotParts.WebServices && dotnet watch msbuild",
    "web-publish": "ng build --configuration production --source-map --output-path=../publish/raw/wwwroot/ --output-hashing=all --aot --base-href=/app/",
    "publish": "npm run v-patch && npm run web-publish && npm run api-publish && npm run api-copy && npm run zip-version",
    "docs": "npm run docs-clean & npx typedoc --out docs --entryPointStrategy expand ./src --excludeExternals --disableSources --name \"Depot Internal\" --excludePrivate --excludeProtected --includeVersion",
    "docs-open": "start chrome %INIT_CWD%/docs/index.html",
    "docs-clean": "cmd /c \"rmdir /q /s docs\"",
    "ngrok": "ngrok http --domain=fitting-well-octopus.ngrok-free.app --host-header=rewrite https://depotparts.local --authtoken 6G4KxvtxRq5f75jxWPguS_69kLhkv3HAdJZStu5pzBi",
    "ngrok-ui": "start chrome http://127.0.0.1:4040",
    "test-output": "powershell . ./package.ps1; Invoke-Create-Zip-File",
    "lint": "ng lint"
  },
  "config": {
    "current_branch": "git branch --show-current"
  },
  "browser": {
    "http": false,
    "https": false,
    "url": false,
    "util": false,
    "net": false
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "~18.2.5",
    "@angular/cdk": "~18.2.5",
    "@angular/common": "~18.2.5",
    "@angular/compiler": "~18.2.5",
    "@angular/core": "~18.2.5",
    "@angular/forms": "~18.2.5",
    "@angular/material": "~18.2.5",
    "@angular/material-date-fns-adapter": "~18.2.5",
    "@angular/platform-browser": "~18.2.5",
    "@angular/platform-browser-dynamic": "~18.2.5",
    "@angular/router": "~18.2.5",
    "@angular/service-worker": "~18.2.5",
    "@date-fns/utc": "2.1.0",
    "@fortawesome/fontawesome-free": "6.6.0",
    "@kolkov/angular-editor": "~3.0.0-beta.0",
    "@microsoft/signalr": "~8.0.7",
    "@swimlane/ngx-charts": "~20.5.0",
    "core-js": "~3.38.1",
    "cropperjs": "~1.6.2",
    "date-fns": "~3.6.0",
    "date-fns-tz": "~3.1.3",
    "fast-json-patch": "~3.1.1",
    "ngx-lightbox": "~3.0.0",
    "print-js": "~1.6.0",
    "rxjs": "~7.8.0",
    "sourcemapped-stacktrace": "~1.1.11",
    "tslib": "~2.7.0",
    "underscore": "~1.13.7",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "~18.2.5",
    "@angular-eslint/builder": "18.3.1",
    "@angular-eslint/eslint-plugin": "18.3.1",
    "@angular-eslint/eslint-plugin-template": "18.3.1",
    "@angular-eslint/schematics": "18.3.1",
    "@angular-eslint/template-parser": "18.3.1",
    "@angular/cli": "~18.2.5",
    "@angular/compiler-cli": "~18.2.5",
    "@angular/language-service": "~18.2.5",
    "@stylistic/eslint-plugin-ts": "2.8.0",
    "@types/jasmine": "~5.1.4",
    "@types/jasminewd2": "~2.0.13",
    "@types/node": "~22.5.5",
    "@types/underscore": "~1.11.15",
    "@typescript-eslint/eslint-plugin": "~7.16.1",
    "@typescript-eslint/eslint-plugin-tslint": "^7.0.2",
    "@typescript-eslint/parser": "~7.16.1",
    "autoprefixer": "~10.4.19",
    "browser-sync": "~3.0.3",
    "codelyzer": "~6.0.2",
    "eslint": "^8.57.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-import": "^2.29.1",
    "eslint-plugin-jsdoc": "^48.7.0",
    "eslint-plugin-simple-import-sort": "^12.1.1",
    "eslint-plugin-you-dont-need-lodash-underscore": "^6.14.0",
    "jasmine-core": "~5.3.0",
    "jasmine-spec-reporter": "~7.0.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage-istanbul-reporter": "~3.0.3",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "maildev": "~2.1.0",
    "ngrok": "~5.0.0-beta.2",
    "postcss": "~8.4.39",
    "prettier": "~3.3.3",
    "protractor": "~7.0.0",
    "tailwindcss": "~3.4.13",
    "ts-node": "~10.9.2",
    "tsdoc": "~0.0.4",
    "typedoc": "~0.26.7",
    "typescript": "~5.4.5"
  }
}
