import { HttpStatusCode } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { HelperService, WindowWrapper } from '@depot/@common';

@Injectable({
  providedIn: 'root'
})
export class CustomErrorHandler implements ErrorHandler {
  constructor(
    private helper: HelperService,
    private window: WindowWrapper,
  ) {
  }

  handleError(error: Error | any) {

    if (error && error.message) {
      this.helper.logger.error(error.message, error);
    } else {
      this.helper.logger.error('Error in application', error);
    }
    if (error?.status === HttpStatusCode.ServiceUnavailable) {
      return;
    }
    this.helper.showMessage(error.message, 'error', 5000, 'Copy Details', async () => {
      const message = `
Date: ${new Date().toLocaleString()}
User: ${this.window.user}
Path: ${this.window.document.location.toString()}
Message: ${error.message}
      `.trim();
      console.error(message);
      await this.window.navigator.clipboard.writeText(message);
      this.helper.showMessage('Details copied to clipboard', 'success');
    });
    // this.helper.logger.error('Uncaught Error', error);
  }
}
