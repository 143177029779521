import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  public static AspectRatio = 4 / 3;
  public static ImageWidth = 800;
  public static ImageHeight = 600;
  // public get SelectedCamera() {
  //   return this.window.localStorage.getItem('selected_camera');
  // }
  // public set SelectedCamera(value: string) {
  //   this.window.localStorage.setItem('selected_camera', value);
  // }

  public cameraDevices$ = new Subject<MediaDeviceInfo[]>();

  constructor(
    // private window: WindowWrapper,
  ) { }

  public blobToBytesAsync(blob: Blob): Promise<number[]> {
    return new Promise((success) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const buffer = reader.result;
        const arr = new Uint8Array(<ArrayBuffer>buffer);
        const res = Array.from(arr);
        success(res);
      };
      reader.readAsArrayBuffer(blob);
    });
  }

  public blobToBase64Async(blob: Blob): Promise<string> {
    return new Promise((success) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        success(<string>reader.result);
      };
      reader.readAsDataURL(blob);
    });
  }

  public async base64ToBlobAsync(base64Url: string): Promise<Blob> {
    const response = await fetch(base64Url);
    return response.blob();
  }

  public async base64ToBytesAsync(base64Url: string): Promise<number[]> {
    const response = await fetch(base64Url);
    const arrayBuffer = await response.arrayBuffer();
    const arr = new Uint8Array(arrayBuffer);
    return Array.from(arr);
  }

  public bytesToBase64(buffer: number[]): string {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);

  }

  public videoToBlobAsync(video: HTMLVideoElement): Promise<Blob> {
    return new Promise(accept => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);


      canvas.toBlob((blob) => {
        accept(blob);
      }, 'image/jpeg');

    });
  }
  public videoToUrl(video: HTMLVideoElement): string {
    if (video.videoWidth === 0 || video.videoHeight === 0) {
      return null;
    }
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
    return canvas.toDataURL('image/jpeg');


  }
  public async getCamerasAsync() {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const cameras = devices.filter(inputs => inputs.kind === 'videoinput');
    this.cameraDevices$.next(cameras);
    return cameras;
  }

  public getStream(cameraId: string) {
    return navigator.mediaDevices.getUserMedia({
      audio: false,
      video: {
        deviceId: { exact: cameraId },
        // width: { ideal: ImageService.ImageWidth },
        // height: { ideal: ImageService.ImageHeight },
        aspectRatio: { ideal: ImageService.AspectRatio },
      }
    });
  }

  // public async isCameraPermitted() {
  //   const permissionStatus = await navigator.permissions.query({
  //     name: 'camera'
  //   })
  //   return permissionStatus.state !== 'denied';
  // }

  public getFileContentType(fileName: string): { contentType: string; native: boolean } {
    if (fileName) {
      const fileExtension = fileName.replace(/^.*\./, '')?.toLowerCase();
      const types = {
        'png': { contentType: 'image/png', native: true },
        'jpg': { contentType: 'image/jpg', native: true },
        'jpeg': { contentType: 'image/jpg', native: true },
        'gif': { contentType: 'image/gif', native: true },
        'pdf': { contentType: 'application/pdf', native: true },
        'xml': { contentType: 'text/xml', native: true },
        'json': { contentType: 'application/json', native: true },
        'xls': { contentType: 'application/vnd.ms-excel', native: false },
        'xlsx': { contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', native: false },
        'wav': { contentType: 'audio/x-wav', native: false },
        'csv': { contentType: 'text/csv', native: true },
        'docx': { contentType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', native: false },
        'svg': { contentType: 'image/svg+xml', native: true },
        'ppt': { contentType: 'application/vnd.ms-powerpoint', native: false },
        'pptx': { contentType: 'application/vnd.openxmlformats-officedocument.presentationml.presentation', native: false },
        'txt': { contentType: 'text/plain', native: true },
      };

      return types[fileExtension] ?? types['txt'];

    }
    return { contentType: 'text/plain', native: true };
  }

}
