import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';

import { CancelRequestService } from '@depot/@common';

import { takeUntil } from 'rxjs';

@Injectable()
export class CancelRequestInterceptor implements HttpInterceptor {

  constructor(
    router: Router,
    private httpCancelService: CancelRequestService) {
    router.events.subscribe(event => {
      if (event instanceof ActivationEnd && (event as ActivationEnd).snapshot.url.length > 0) {
        this.httpCancelService.cancelPendingRequests();
      }
    });
  }

  intercept<T>(req: HttpRequest<T>, next: HttpHandler) {
    if (req.headers.has('dp-never-cancel')) {
      return next.handle(req);

    }

    return next.handle(req).pipe(takeUntil(this.httpCancelService.onCancelPendingRequests()));
  }

}
