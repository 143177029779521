export function capitalize(input: string | null) {
  if (!input) {
    return input;
  }
  if (input.length <= 1) {
    return input;
  }

  return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
}
