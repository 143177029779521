import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IDisplayMessageSearch } from '@depot/@data';
import { IDisplayMessage, IPagedResults } from '@depot/custom';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class DisplayMessageRepositoryService {
  constructor(private httpClient: HttpClient) { }

  public searchDisplayMessages(filters: IDisplayMessageSearch) {
    const url = environment.get_endpoint('displayMessage');

    return this.httpClient.get<IPagedResults<IDisplayMessage>>(url, { params: <any>filters });
  }

  public getDisplayMessage(id: number) {
    const url = environment.get_endpoint(`displayMessage/${id}`);
    return this.httpClient.get<IDisplayMessage>(url);
  }

  public saveDisplayMessage(id: number, data: IDisplayMessage) {
    const url = environment.get_endpoint(`displayMessage/${id}`);
    return this.httpClient.put<IDisplayMessage>(url, data);
  }

  public deleteDisplayMessage(id: number) {
    const url = environment.get_endpoint(`displayMessage/${id}`);
    return this.httpClient.delete<IDisplayMessage>(url);
  }

}
