import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'depot-prompt-dialog',
  template: `
  <h1 mat-dialog-title>{{Title}}</h1>
  <div mat-dialog-content>
  {{Message}}
  <br />
  <mat-form-field>
    <mat-label>{{Placeholder}}</mat-label>
    <input matInput
            required
            data-lpignore="true"
            [(ngModel)]="Value"
            type="text" />
  </mat-form-field>
  </div>
  <div mat-dialog-actions
    align="center">
    <button mat-raised-button (click)="dialogRef.close(null)">Cancel</button>
    <button mat-raised-button color="primary" (click)="dialogRef.close(Value)">Ok</button>
  </div>
  `,
})
export class PromptDialogComponent {
  public Title = '';
  public Message: SafeHtml = '';
  public Placeholder = '';
  public Value = '';

  constructor(
    public dialogRef: MatDialogRef<PromptDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      title?: string;
      message?: string;
      placeholder?: string;
      value?: string;
    }) {

    data = data || {};
    this.Title = data.title || 'Please Confirm';
    this.Message = data.message || 'Are you sure you want to do this?';
    this.Placeholder = data.placeholder || '';
    this.Value = data.value || '';
  }
}
