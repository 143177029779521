import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IDepotContact, IDepotLocation, IEmployee } from '@depot/custom';
import { environment } from '@env';

import { of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserRepositoryService {
  private timeZones: { displayName: string; id: string }[];
  private locations: IDepotLocation[];
  constructor(private httpClient: HttpClient) { }

  public getUsers() {
    const url = environment.get_endpoint(`user`);

    return this.httpClient.get<IEmployee[]>(url, {});

  }

  public getTimezones() {
    if (this.timeZones) {
      return of(this.timeZones);
    }
    const url = environment.get_endpoint(`user/GetTimezones`);

    return this.httpClient.get<{ displayName: string; id: string }[]>(url, {})
      .pipe(tap(x => this.timeZones = x));


  }
  public getDepotLocations() {
    if (this.locations) {
      return of(this.locations);
    }
    const url = environment.get_endpoint(`user/DepotLocations`);

    return this.httpClient.get<IDepotLocation[]>(url, {})
      .pipe(tap(x => this.locations = x));
  }

  public getDepotContacts(id: string) {
    const url = environment.get_endpoint(`user/DepotContacts/${id}`);

    return this.httpClient.get<IDepotContact>(url);
  }

  public saveDepotContacts(depotContact: IDepotContact) {
    const url = environment.get_endpoint(`user/DepotContacts`);

    return this.httpClient.post<IDepotContact>(url, depotContact);
  }

  public getUser(id: string) {
    const url = environment.get_endpoint(`user/${id}`);

    return this.httpClient.get<IEmployee>(url);
  }


  public saveUser(saveData: IEmployee) {
    const url = environment.get_endpoint(`user/${saveData.id}`);

    return this.httpClient.put<IEmployee>(url, saveData);
  }

  public deleteUser(id: string) {
    const url = environment.get_endpoint(`user/${id}`);

    return this.httpClient.delete<void>(url);
  }

  // public deleteAuditRow(id: number) {
  //   const url = environment.get_endpoint(`audit/${id}`);

  //   return this.httpClient.delete(url);
  // }

}
