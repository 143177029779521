import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '@depot/@common';

import { catchError, map, mergeMap, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard {
  // Don't use these.  They are used externally so if we ever merge
  // auth systems this would give them access
  // public static General = 'General';
  // public static DocumentUser = 'DocumentUser';
  public static Admin = 'Admin';
  public static UserAdmin = 'UserAdmin';
  public static AuditAdmin = 'AuditAdmin';
  public static SiteAdmin = 'SiteAdmin';
  public static PickupAdmin = 'PickupAdmin';
  public static PickupSuperAdmin = 'PickupSuperAdmin';
  public static IncomingAdmin = 'IncomingAdmin';
  public static WeightTicketAdmin = 'WeightTicketAdmin';
  public static ScrapAdmin = 'ScrapAdmin';
  public static PriceBookEditAdmin = 'PriceBookEditAdmin';
  public static OrderManager = 'OrderManager';


  path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;

  constructor(
    private authService: AuthService,
    private router: Router,
  ) { }
  /**
   * @description Determine if the current user is in a specific role.
   * If the user isn't known then wait until they are known
   */
  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {
    const config = route.routeConfig;

    return this.authService.user$.pipe(
      // filter(user => user !== undefined),
      mergeMap(user => {
        if (user === undefined) {
          return this.authService
            .getUserData()
            .pipe(catchError(() => of(null)));
        }
        return of(user);
      }),
      map((user) => {
        if (user === null) {

          this.router.navigate(['/login'], {
            queryParams: {
              returnUrl: state.url
            }
          });

        }
        return this.authService.isInRole(config.data.roles);
      })
    );

  }


}
