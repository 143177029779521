import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'count',
  pure: true
})
export class CountPipe implements PipeTransform {

  transform(items: any[], attr: string): any {
    if (!items) {
      return;
    }
    if (attr) {
      return items.map(x => x[attr].length);
    }
    return items.length;
  }

}
