import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StringsService {

  constructor() { }

  /**
   * Camelize a string, cutting the string by multiple separators like
   * hyphens, underscores and spaces.
   * https://ourcodeworld.com/articles/read/608/how-to-camelize-and-decamelize-strings-in-javascript
   */
  public camelize(text: string) {
    return text.replace(/^([A-Z])|[\s-_]+(\w)/g, (match, p1, p2, offset) => {
      if (p2) {
        return p2.toUpperCase();
      }
      return p1.toLowerCase();
    });
  }

}
