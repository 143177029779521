import { animate, animateChild, group, query, state, style, transition, trigger } from '@angular/animations';

// https://blog.thoughtram.io/angular/2016/09/16/angular-2-animation-important-concepts.html
// THESE WORK! THEY CAUSED ISSUES WITH THE EXPANSION PANELS ON THE LIST PAGES AND AT THE TIME I DIDN'T DIG INTO IT
export const fadeAnimation =
  trigger('fadeAnimation', [
    // the fade-in/fade-out animation.

    // the "in" style determines the "resting" state of the element when it is visible.
    state('in', style({ opacity: 1 })),

    // fade in when created. this could also be written as transition('void => *')
    transition(':enter', [
      style({ opacity: 0 }),
      animate(400)
    ]),

    // fade out when destroyed. this could also be written as transition('void => *')
    transition(':leave',
      animate(400, style({ opacity: 0 })))

  ]);


