import { HttpErrorResponse } from '@angular/common/http';
import { DestroyRef, Injectable, NgZone, signal, Type } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';

import { ClientLogging, DepotContextService, StringsService, WindowWrapper } from '@depot/@common';
import { ConfirmationDialogComponent, MessageDialogComponent } from '@depot/@components';
import { environment } from '@env';

import { BehaviorSubject, first } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  public IsGlobalSpinner$ = signal(false);
  public IsGlobalBar$ = signal(false);
  public logger: ClientLogging;
  // public sanitizer = inject(DomSanitizer);

  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private window: WindowWrapper,
    private depotContext: DepotContextService,
    private zone: NgZone,
    private stringsService: StringsService,
    // private lightbox: Lightbox,
    private destroyRef: DestroyRef
  ) {

    this.logger = new ClientLogging({
      application: 'Depot-InternalClient',
      endPoint: environment.get_endpoint('logger/'),
      debug: true,
      filter: (msg) => {
        if (this.window.logLevel) {
          return msg.level >= this.window.logLevel;
        }
        return true;
      },
    }, this.window, this.depotContext);

  }

  showMessage(
    message: string,
    type: 'success' | 'error' | 'warn' | 'info' = 'error',
    delay = 5000,
    actionText = '',
    onClick: (snackBar: MatSnackBarRef<SimpleSnackBar>) => void = null) {

    this.zone.run(() => {
      const snackbar = this.snackBar.open(message, actionText, {
        duration: delay,
        verticalPosition: 'bottom',
        panelClass: `${type}-message`,
      });

      if (onClick) {
        snackbar
          .onAction()
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(() => onClick(snackbar));
      }

    });
  }

  async confirmDialog(message: string) {
    return new Promise((accept: (val: boolean) => void) => {

      this.dialog
        .open(ConfirmationDialogComponent, {
          data: { message: message },
        })
        .afterClosed()
        .pipe(first())
        .subscribe((isConfirmed: boolean) => {
          accept(isConfirmed === true);
        });
    });
  }

  async showAlert(message: string, title: string | null = null, component: Type<any> = null, additionalProperties: any = null) {
    setTimeout(() => {
      this.dialog
        .open(MessageDialogComponent, {
          data: {
            message: message,
            title: title,
            component: component
          },
          ...(additionalProperties ?? {})
        })
        .afterClosed()
        .pipe(first())
        .subscribe(() => {

        });

    }, 0);
  }

  // public showLightbox(imagePaths: { imagePath: string, caption: string }[], index: number, albumLabel: string) {
  //   const lightboxImages = imagePaths.map<IAlbum>(x => {
  //     return {
  //       src: <any>this.sanitizer.bypassSecurityTrustUrl(x.imagePath),
  //       thumb: null,
  //       caption: x.caption,
  //       downloadUrl: null,
  //     };
  //   });

  //   this.lightbox.open(lightboxImages, index, <LightboxConfig>{
  //     fadeDuration: 0.25,
  //     resizeDuration: 0.25,
  //     wrapAround: true,
  //     showImageNumberLabel: true,
  //     albumLabel: albumLabel
  //   });
  // }

  public isInViewport(element: Element) {
    if (!element) {
      return false;
    }
    const rect = element.getBoundingClientRect();
    const html = this.window.document.documentElement;

    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (this.window.innerHeight || html.clientHeight) &&
      rect.right <= (this.window.innerWidth || html.clientWidth)
    );
  }

  public mapErrors(
    resp: any | any[] | string,
    controls: AbstractControl[],
    form: UntypedFormGroup,
    globalErrors$: BehaviorSubject<string[]> | null) {
    let errorList: {
      [key: string]: string[];
    }[] | string[] = [];
    if (typeof (resp.traceId) === 'string') {
      // its a iis handled error, get the main error message
      resp = resp.errors ?? 'Unknown error occurred';
    } else if (resp instanceof HttpErrorResponse) {
      if (Array.isArray(resp?.error)) {
        // this is expected and do nothing. This occurs when BadRequest(response.Select(x => new ClientError(x.ClientErrors)))
        resp = resp.error;
      } else if (resp.error?.errors) {
        // this occurs when using the returns of return NotFound(new ClientError("Test 1"))
        resp = resp.error;
      } else if (typeof (resp.error) === 'string' || resp.error instanceof String) {
        // this occurs when using the returns of return NotFound("Test 1")
        resp = { errors: [resp.error] };
      } else {
        // this occurs when using the returns of return NotFound()
        resp = { errors: [resp.message] };
      }
    } else if (typeof (resp) === 'string' || resp instanceof String) {
      resp = { errors: [resp] };
    }

    if (Array.isArray(resp)) {
      errorList = resp;
    } else {
      errorList = [resp.errors];
    }

    const globalErrorsTemp = globalErrors$?.getValue() ?? [];
    for (let idx = 0; idx < errorList.length; idx++) {
      let error = errorList[idx];
      if (error) {
        if ((<any>error).errors !== undefined) {
          error = <any>(<any>error).errors;
        }
        if (!error) {
          continue;
        }
        const keys = Object.keys(error);
        for (let keyIdx = 0; keyIdx < keys.length; keyIdx++) {
          const key = keys[keyIdx];
          if (typeof (error[key]) === 'string' || error[key] instanceof String) {
            error[key] = [<any>error[key]];
          }
          const nestedKeys = Object.keys(error[key]);
          for (let errIdx = 0; errIdx < nestedKeys.length; errIdx++) {
            try {
              const err = error[key][nestedKeys];
              let formField: AbstractControl = null;
              if (controls) {
                formField = controls[idx].get(key);
              }
              if (!formField && form) {
                formField = form.get(key);
              }

              if (!formField && form && key.includes('[')) {
                // this only happens when a form has nested form array
                // Example: orderLines[0].DepotNotesWi
                const regex = /\[.*?\]/g;
                const index = <any>key.match(regex)[0].replace('[', '').replace(']', '');
                const paths = key.replace(regex, '').split('.').map(this.stringsService.camelize);

                formField = (<UntypedFormArray>form.get(paths[0]))?.at(index)?.get(paths[1]);
              }

              if (formField) {
                formField.updateValueAndValidity();
                formField.markAsTouched();
                formField.markAsDirty();
                formField.setErrors({
                  'validation': err
                });

              } else {
                globalErrorsTemp.push(err);
              }
            } catch (err) {
              this.logger.error('Error mapping data', err);
            }
          }
        }
      }
    }
    if (globalErrors$ != null) {
      globalErrors$.next(globalErrorsTemp);
    } else {
      this.showMessage(globalErrorsTemp.join('\n'));
    }


  }

  public getGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, char => {
      const rand = Math.random() * 16 | 0;
      const v = char === 'x' ? rand : (rand & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  public validateGuid(guid: string) {
    const match = guid.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
    if (match === null) {
      return false;
    }
    return true;

  }

  public removeQueryParam(name: string, url: string = this.window.location.search) {
    let baseUrl = url.includes('?') ? url.split('?')[0] : '';
    const params = new URLSearchParams(url.includes('?') ? url.split('?')[1] : url);
    params.delete(name);
    if (params.toString()?.length > 0) {
      baseUrl += '?' + params.toString();
    }
    return baseUrl;
  }

  public appendQueryParam(name: string, value: string, url: string = this.window.location.search) {
    let baseUrl = url.includes('?') ? url.split('?')[0] : url;
    const params = new URLSearchParams(url.includes('?') ? url.split('?')[1] : '');
    params.append(name, value);
    if (params.toString()?.length > 0) {
      baseUrl += '?' + params.toString();
    }
    return baseUrl;
  }

  public getQueryParam(name: string, url: string) {
    return new URLSearchParams(url.includes('?') ? url.split('?')[1] : url).get(name);
  }

  public validateForm(form: UntypedFormGroup, markAsDirty = true) {

    if (form.valid === false) {
      if (markAsDirty === true) {
        form.markAllAsTouched();
        this.markControlDirty((Object as any).values(form.controls));
      }
      return false;
    }
    return true;
  }

  private markControlDirty(controls: AbstractControl[]) {
    controls.forEach((control: AbstractControl) => {
      control.markAsDirty();
      (<any>control.statusChanges).emit(control.status);
      const nestedControls = (<any>control).controls;
      if (nestedControls) {
        this.markControlDirty((Object as any).values(nestedControls));

      }
    });
  }



}
