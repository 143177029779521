import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

export const NeverCancelHeader = { 'dp-never-cancel': 'true' };


@Injectable({
  providedIn: 'root'
})
export class CancelRequestService {

  private pendingHttpRequests$ = new Subject<void>();
  constructor() { }

  public cancelPendingRequests() {
    this.pendingHttpRequests$.next();
  }

  public onCancelPendingRequests() {
    return this.pendingHttpRequests$.asObservable();
  }

}
