import { animate, animateChild, group, query, state, style, transition, trigger } from '@angular/animations';

// https://blog.thoughtram.io/angular/2016/09/16/angular-2-animation-important-concepts.html
// THESE WORK! THEY CAUSED ISSUES WITH THE EXPANSION PANELS ON THE LIST PAGES AND AT THE TIME I DIDN'T DIG INTO IT
export const routeAnimation =
  trigger('routeAnimation', [
    transition('* <=> *', [
      // Set a default  style for enter and leave
      query(':enter, :leave', [
        style({
          position: 'absolute',
          left: 0,
          width: '100%',
          opacity: 0,

        }),
      ], { optional: true }),
      // Animate the new page in
      query(':enter', [
        animate('600ms ease', style({ opacity: 1, })),
      ], { optional: true })
    ]),



  ]);


