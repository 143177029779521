import { Injectable } from '@angular/core';

import { AuthService, DepotContextService } from '@depot/@common';
import { IScrapReportSearch } from '@depot/@data';
import { ILocalNotificationSettings } from '@depot/custom';

import { BehaviorSubject } from 'rxjs';

type themeValues = 'depot-light' | 'depot-dark';
@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  public themeIsDark$ = new BehaviorSubject<boolean>(false);

  constructor(
    private depotContext: DepotContextService,
    private authService: AuthService,
  ) { }

  public async notification(value: ILocalNotificationSettings | null = null): Promise<ILocalNotificationSettings> | null {
    const defaultSettings: ILocalNotificationSettings = {
      showNotification: false,
      playSound: false,
      showInTitle: true
    };
    if (value === null) {
      value = await this.depotContext.getSettingAsync<ILocalNotificationSettings>('settings_notification', this.authService.user.userName);
    } else {
      await this.depotContext.saveSettingAsync<ILocalNotificationSettings>('settings_notification', this.authService.user.userName, value);
    }
    return value ?? defaultSettings;
  }

  public async selectedCamera(value: string | null = null): Promise<string> | null {
    if (value === null) {
      return await this.depotContext.getSettingAsync<string>('selected-camera', 'general');
    } else {
      await this.depotContext.saveSettingAsync<string>('selected-camera', 'general', value);
      return value;
    }
  }
  public async navSize(value: boolean | null = null): Promise<boolean> | null {

    if (value === null) {
      return await this.depotContext.getSettingAsync<boolean>('navSize', 'general');
    } else {
      await this.depotContext.saveSettingAsync<boolean>('navSize', 'general', value);
      return value;
    }
  }

  public async themeIsDark(value: boolean | null = null): Promise<boolean> | null {

    if (value === null) {
      value = await this.depotContext.getSettingAsync<boolean>('depot-theme-dark', this.authService.user?.userName ?? 'general');
    } else {
      await this.depotContext.saveSettingAsync<boolean>('depot-theme-dark', this.authService.user?.userName ?? 'general', value);
    }
    this.themeIsDark$.next(value ?? false);
    return value ?? false;
  }

  public async dashboardSettings<T>(key: string, value: T | null = null): Promise<T> {

    if (value === null) {
      return await this.depotContext.getSettingAsync<T>(key, this.authService.user.userName);
    } else {
      await this.depotContext.saveSettingAsync<T>(key, this.authService.user.userName, value);
      return value;
    }
  }

}


