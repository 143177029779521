<mat-toolbar-row class="tw-mt-4">

  <button mat-icon-button
          type="button"
          (click)="cropper.rotate(-90)"
          title="Rotate Left">
    <span class="fa fa-undo"></span>
  </button>

  <button mat-icon-button
          type="button"
          (click)="cropper.rotate(90)"
          title="Rotate Right">
    <span class="fa fa-redo"></span>
  </button>

  <mat-divider class="style-fix"
               [vertical]="true"></mat-divider>

  <button mat-icon-button
          type="button"
          (click)="cropper.zoom(0.1)"
          title="Zoom In">
    <span class="fa fa-search-plus"></span>
  </button>

  <button mat-icon-button
          type="button"
          (click)="cropper.zoom(-0.1)"
          title="Zoom Out">
    <span class="fa fa-search-minus"></span>
  </button>

  <mat-divider class="style-fix"
               [vertical]="true"></mat-divider>

  <button mat-icon-button
          type="button"
          (click)="setDragMode('move')"
          title="Move">
    <span class="fa fa-arrows-alt"></span>
  </button>

  <button mat-icon-button
          type="button"
          (click)="setDragMode('crop')"
          title="Crop">
    <span class="fa fa-crop"></span>
  </button>

  <mat-divider class="style-fix"
               [vertical]="true"></mat-divider>

  <button mat-icon-button
          type="button"
          (click)="cropper.crop()"
          title="Edit">
    <span class="fa fa-edit"></span>
  </button>

  <button mat-icon-button
          type="button"
          (click)="cropper.clear()"
          title="Cancel Edit">
    <span class="fa fa-times"></span>
  </button>

  <mat-divider class="style-fix"
               [vertical]="true"></mat-divider>

  <button mat-icon-button
          type="button"
          (click)="cropper.reset()"
          title="Reset">
    <span class="fa fa-sync"></span>
  </button>

  <span class="dp-flex-space"></span>
  <mat-form-field style="width: 75px;">
    <mat-label>Order</mat-label>
    <input matInput
           [(ngModel)]="partImage.sortOrder"
           type="text"
           required>
  </mat-form-field>

  <mat-slide-toggle *ngIf="partImage"
                    [(ngModel)]="partImage.isExternal">
    <span style="font-size: 15px">
      {{partImage.isExternal ? "External":"Internal"}}
    </span>
  </mat-slide-toggle>
</mat-toolbar-row>
<mat-dialog-content>
  <img #imgSrc
       style="max-width: 100%;"
       src="" />
</mat-dialog-content>

<mat-dialog-actions align="end">
  <!-- <div *ngIf="(imageInfo | async) as imageData">
    Width {{imageData?.width?.toFixed(0)}}px&nbsp;
    Height {{imageData?.height?.toFixed(0)}}px&nbsp;
    Rotation {{imageData?.rotate}}%&nbsp;
  </div> -->
  <!-- <span class="dp-flex-space"></span> -->

  <button mat-raised-button
          (click)="dialogRef.close(null)">Cancel</button>
  <button mat-raised-button
          color="primary"
          (click)="save()">Save</button>

</mat-dialog-actions>
