import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';

import { AuthService } from '@depot/@common';

import { filter, Subject, Subscription, take } from 'rxjs';

import { format } from 'date-fns';

@Component({
  selector: 'depot-default-child',
  template: `<router-outlet></router-outlet>`,
})
export class DefaultChildComponent implements OnInit, OnDestroy {
  private sub: Subscription;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService) {

  }

  ngOnInit() {

    this.sub = this.router.events
      .pipe(filter(x => x instanceof NavigationStart))
      .subscribe((x: NavigationStart) => {

        this.navigateToFirstChild(x.url.toLowerCase());

      });
    this.navigateToFirstChild(this.router.url.toLowerCase());

  }

  private navigateToFirstChild(url: string) {

    const snapshot = this.activatedRoute.snapshot;

    if (url.startsWith('/')) {
      url = url.substring(1);
    }
    if (this.activatedRoute.snapshot.routeConfig.path === url) {
      if (!Array.isArray(snapshot?.data?.children)) {
        return;
      }
      for (const child of snapshot.data.children) {
        // The assumption is that this route is only visible when user is auth
        // This is done on the routes CanActivate having RoleGuard as a value
        if (this.authService.isInRole(child.roles)) {
          this.router.navigate([child.path], {
            replaceUrl: true
          });
          return;
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }


}
