import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safe',
  pure: true
})
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  transform(content: string, safeType?: 'url' | 'html'): any {
    if (safeType === 'url') {
      return this.sanitizer.bypassSecurityTrustResourceUrl(content);
    } else if (safeType === 'html') {
      return this.sanitizer.bypassSecurityTrustHtml(content);
    }

    throw new Error('No parse type defined in SafePipe');
  }

}
